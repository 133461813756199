import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

const CheckInboxMessage: FC = () => {
	const { t } = useTranslation("translation", { keyPrefix: "checkInbox" });

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      textAlign="center"
      gap={1}
      my={3}
    >
      <Typography variant="h5" fontWeight="bold">
        {t("header")}
      </Typography>
      <Typography variant="body1" color="text.secondary">
        {t("confirmEmail")}
      </Typography>
      <MailOutlineIcon
        sx={{
          fontSize: 58,
          color: "primary.main",
        }}
      />
    </Box>
  );
};

export default CheckInboxMessage;
