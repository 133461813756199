import "@fontsource/irish-grover/400.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import { useRouteError } from "react-router-dom";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getRouterService } from "../../../diContainer/getDependencies";
import { isIssuerConfigError } from "../../../utils/response-errors";
import PlaneRightImg from "../../images/PlaneRightImg";
import PlaneLeftImg from "../../images/PlaneLeftImg";
import styles from "./ErrorPage.module.scss";

function ErrorPage() {
  const { t } = useTranslation("translation", { keyPrefix: "errorPage" });

  const routerService = getRouterService();

  const error = useRouteError() as any;

  const handleGoToLanding = () => {
    window.open(process.env.REACT_APP_LOYALTY_LANDING_URL, "_self");
  };

  const handleGoToSignin = () => {
    routerService.navigateToIssuerPath("/signin");
  };

  const handleClose = () => {
    isIssuerConfigError(error) ? handleGoToLanding() : handleGoToSignin();
  };

  return (
    <Box className={styles.container}>
      <div className={styles.content}>
        <div className={styles.imageTop}>
          <PlaneRightImg />
        </div>
        <h1 className={styles.title}>{t("unknownTitle")}</h1>
        <p className={styles.description}>{t("unknownText")}</p>
        <button className={styles.button} onClick={handleClose}>
          {t("actionText")}
        </button>
        <div className={styles.imageBottom}>
          <PlaneLeftImg />
        </div>
      </div>
    </Box>
  );
}

export default ErrorPage;
