import { lazy, Suspense, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import SettingsLayout from "../../components/layouts/SettingsLayout";
import Loader from "../../components/layouts/Loader";

const ProfileInfoForm = lazy(
  () => import("../../components/ProfileSettings/ProfileInfoForm")
);
const ProfileAddressForm = lazy(
  () => import("../../components/ProfileSettings/ProfileAddressForm")
);
const ProfileBirthForm = lazy(
  () => import("../../components/ProfileSettings/ProfileBirthForm")
);
const ProfileGenderForm = lazy(
  () => import("../../components/ProfileSettings/ProfileGenderForm")
);
const ProfileWorkplaceForm = lazy(
  () => import("../../components/ProfileSettings/ProfileWorkplaceForm")
);

type FormTypes =
  | "birth"
  | "gender"
  | "workplace"
  | "address"
  | "info"
  | undefined;

const formComponents = {
  birth: ProfileBirthForm,
  gender: ProfileGenderForm,
  workplace: ProfileWorkplaceForm,
  address: ProfileAddressForm,
  info: ProfileInfoForm,
};

export const ProfileInfoSettingPage = () => {
  const { t } = useTranslation("translation");

  const { form } = useParams<{ form: FormTypes }>();

  const Form = useMemo(() => {
    const FormComponent = formComponents[form || "info"];
    return FormComponent ? <FormComponent /> : null;
  }, [form]);

  return (
    <SettingsLayout title={t("header.profileInfo") ?? ""}>
      <Suspense fallback={<Loader />}>{Form}</Suspense>
    </SettingsLayout>
  );
};

export default ProfileInfoSettingPage;
