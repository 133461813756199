import type { FC } from "react";
import { Paper, Skeleton, Typography } from "@mui/material";
import type { IBarcodeInfo } from "../../../../boundary/IProfileService";
import { Barcode } from "./Barcode";

interface IProps {
  loading?: boolean;
  barcodeInfo?: IBarcodeInfo | null;
}

const BarcodeBox: FC<IProps> = ({ loading, barcodeInfo }) => {
  if (loading) {
    return (
      <Skeleton animation="wave" variant="rounded" width="100%" height={195} />
    );
  }

  if (!barcodeInfo) return null;

  return (
    <Paper sx={{ padding: "0.5rem" }}>
      <Barcode text={barcodeInfo.text} type={barcodeInfo.type} />

      {barcodeInfo.alterText && (
        <Typography align="center" fontSize="10px" fontWeight="300" marginTop="0.5rem">
          {barcodeInfo.alterText}
        </Typography>
      )}
    </Paper>
  );
};

export default BarcodeBox;
