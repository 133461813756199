import { IFormFieldConfig } from "../../../../boundary/forms/IFormFieldConfig";
import { clearPhoneNumber } from "../../../../utils/utils";

export interface ICompanyForm {
  fullCompanyName: string;
  shortCompanyName: string;
  companyType: string;
  taxCode: string;
  activityType: string;
  companyPhone: string;
  companyEmail: string;
  agreement?: boolean;
}

export const initialValues = {
  fullCompanyName: "",
  shortCompanyName: "",
  companyType: "",
  taxCode: "",
  activityType: "",
  companyPhone: "",
  companyEmail: "",
  agreement: false,
};

export const companyFormConfig: IFormFieldConfig<ICompanyForm>[] = [
  {
    name: "fullCompanyName",
    label: "Повна назва компанії",
    type: "text",
    textFieldProps: {
      multiline: true,
      rows: 2,
    },
    rules: {
      required: "Це поле обов'язкове",
      minLength: {
        value: 3,
        message: "Мінімальна кількість символів - 3",
      },
      maxLength: {
        value: 250,
        message: "Максимальна кількість символів - 250",
      },
    },
  },
  {
    name: "shortCompanyName",
    label: "Скорочена назва компанії",
    type: "text",
    rules: {
      required: "Це поле обов'язкове",
      minLength: {
        value: 2,
        message: "Мінімальна кількість символів - 2",
      },
      maxLength: {
        value: 50,
        message: "Максимальна кількість символів - 50",
      },
    },
  },
  {
    name: "companyType",
    label: "Тип компанії",
    type: "select",
    options: [
      { label: "ТОВ", value: "tov" },
      { label: "ФОП", value: "fop" },
    ],
    rules: {
      required: "Це поле обов'язкове",
    },
  },
  {
    name: "taxCode",
    label: "Код ЄДРПОУ або ІПН",
    type: "number",
    rules: {
      required: "Це поле обов'язкове",
      pattern: {
        value: /^(?:\d{8}|\d{10,12})$/,
        message: "Код має містити 8 або 10-12 цифр",
      },
    },
  },
  {
    name: "activityType",
    label: "Вид діяльності",
    type: "select",
    options: [
      { label: "Роздрібна торгівля", value: "retail" },
      { label: "Оптова торгівля", value: "wholesale" },
      { label: "HoReCa", value: "horeca" },
      { label: "Виробництво", value: "manufacturing" },
      { label: "Сільське господарство", value: "agriculture" },
      { label: "Сфера послуг", value: "services" },
      { label: "Фінансові установи", value: "financial_institutions" },
      {
        label: "Державні/Муніципальні установи",
        value: "government_municipal",
      },
    ],
    rules: {
      required: "Це поле обов'язкове",
    },
  },
  {
    name: "companyPhone",
    label: "Номер телефону",
    type: "phone",
    rules: {
      validate: (val) => {
        const cleanedValue = clearPhoneNumber(val as string);
        const isValid = /^380\d{9}$/.test(cleanedValue);
        return isValid || 'Телефон має бути у форматі 380ХХХХХХХХХ';
      }
    },
  },
  {
    name: "companyEmail",
    label: "Електронна пошта",
    type: "email",
    rules: {
      // required: "Це поле обов'язкове",
      pattern: {
        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        message: "Некоректна електронна пошта",
      },
    },
  },
  {
    name: "agreement",
    type: "agreement",
    rules: {
      required: "Це поле обов'язкове",
    },
  }
];
