import { type FC } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useObservable } from "@roketus/web-toolkit";
import { Box, Button } from "@mui/material";
import { getSignupService } from "../../../diContainer/getDependencies";
import {
  ActionsPaths,
  AnonymousActionsPaths,
} from "../../../adapters/router/actionsRoutes";
import { useIsAuthenticated } from "../../../adapters/hooks/useIsAuthenticated";
import { AuthMethod } from "../../../domain/specs/authMethods";
import type { TSetFormFieldError } from "../../../boundary/forms/TSetFormFieldError";
import type { IEmailFormData } from "../../../boundary/IEmailFormData";
import type { IPhoneFormData } from "../../../boundary/IPhoneFormData";
import LayoutHeadless from "../layouts/LayoutHeadless";
import { BonusActionDescription } from "../../components/actionComponents/BonusActionDescription";
import { CouponActionDescription } from "../../components/actionComponents/CouponActionDescription";
import { NotificationActionDescription } from "../../components/actionComponents/NotificationActionDescription";
import { SurveyActionDescription } from "../../components/actionComponents/SurveyActionDescription";
import { PromoActionDescription } from "../../components/actionComponents/PromoActionDescription";
import AuthForm from "../authForms/AuthForm";

interface IProps {
  isAnonymous: boolean;
  signupByGoogle: () => void;
  onSubmit: (
    phoneOrEmail?: string,
    setError?: TSetFormFieldError<IPhoneFormData> | TSetFormFieldError<IEmailFormData>
  ) => void; // TODO other methods
  onCancel: () => void;
}

export const StandardWelcomePage: FC<IProps> = ({
  isAnonymous,
  signupByGoogle,
  onSubmit,
  onCancel,
}) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "welcomePage",
  });
  const { t: tForm } = useTranslation("translation", {
    keyPrefix: "signUp.index",
  });

  const signUpService = getSignupService();

  const location = useLocation();
  const actionType = location.pathname.split("/").at(-1);

  const signupData = useObservable(signUpService.data$);

  const isAuthenticated = useIsAuthenticated();

  const signupByPhone = (
    phone: string,
    setError: TSetFormFieldError<IPhoneFormData>
  ) => {
    onSubmit(phone, setError);
  };

  const signupByEmail = (
    email: string,
    setError: TSetFormFieldError<IEmailFormData>
  ) => {
    onSubmit(email, setError);
  };

  const changeSignupMethod = (method: AuthMethod) => {
    switch (method) {
      case AuthMethod.Google:
        signupByGoogle();
        break;
      case AuthMethod.Phone:
      case AuthMethod.Email:
      default:
        signUpService.setSignupMethod(method);
    }
  };

  return (
    <LayoutHeadless>
      {actionType === ActionsPaths.Bonus && <BonusActionDescription />}
      {(actionType === ActionsPaths.Coupon ||
        actionType === AnonymousActionsPaths.AnonymousCoupon) && (
        <CouponActionDescription isAnonymous={isAnonymous} />
      )}
      {(actionType === ActionsPaths.Promo ||
        actionType === ActionsPaths.VodohraiPromo ||
        actionType === ActionsPaths.SensePromo) && <PromoActionDescription />}
      {actionType === ActionsPaths.Notification && (
        <NotificationActionDescription />
      )}
      {actionType === ActionsPaths.Survey && <SurveyActionDescription />}

      {!isAnonymous && !isAuthenticated && (
        <AuthForm
          t={tForm}
          hasPolicy
          loading={signupData?.loading}
          method={signupData?.signupMethod}
          setAuthMethod={changeSignupMethod}
          authByPhone={signupByPhone}
          authByEmail={signupByEmail}
        />
      )}

      {(isAnonymous || isAuthenticated) && (
        <Box display="flex" justifyContent="flex-end" gap={2}>
          <Button variant="outlined" onClick={onCancel}>
            {t("cancel")}
          </Button>
          <Button variant="contained" onClick={() => onSubmit()}>
            {t("ok")}
          </Button>
        </Box>
      )}
    </LayoutHeadless>
  );
};
