import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useObservable } from "@roketus/web-toolkit";
import { Box, Link, Typography } from "@mui/material";
import {
  getMessageBus,
  getSignupService,
} from "../../../diContainer/getDependencies";
import { buildAnalyticMessageEntity } from "../../../domain/entities/analyticEntity";
import type { IProfileBirthForm } from "../../../boundary/forms/IProfileForm";
import { getProfileBirthFieldsConfig } from "../../components/ProfileSettings/fields";
import FormBuilder from "../../components/form/FormBuilder";

const defaultValues: IProfileBirthForm = {
  birth: null,
};

const SignupBirthPage: FC = () => {
  const { t: tCommon } = useTranslation("translation");
  const { t } = useTranslation("translation", {
    keyPrefix: "signUp.setProfile",
  });

  const signupService = getSignupService();
  const messageBus = getMessageBus();

  const data = useObservable(signupService.data$);

  const onSubmit = (form: IProfileBirthForm) => {
    const analyticEvent = buildAnalyticMessageEntity({
      action: "SetProfileBirthButtonClick",
      source: "SignUpProfilePage",
    });
    messageBus.send(analyticEvent);

    signupService.setBirth(form);
  };

  const handleSkipStep = () => {
    signupService.skipStep("birth");
  };

  const fieldsConfig = getProfileBirthFieldsConfig(tCommon);

  return (
    <>
      <Typography component="h1" variant="h6" textAlign="left" width="100%">
        {t("birthHeader")}
      </Typography>
      <FormBuilder<IProfileBirthForm>
        tError={t}
        isLoading={data?.loading}
        initialValues={defaultValues}
        fieldsConfig={fieldsConfig}
        submitLabel={t("continueButton")}
        onSave={onSubmit}
      />
      <Box textAlign="right" sx={{ width: "100%" }}>
        {data?.stepData?.nextStepSkippable && (
          <Link underline="always" onClick={handleSkipStep}>
            {t("skip")}
          </Link>
        )}
      </Box>
    </>
  );
};

export default SignupBirthPage;
