import { decodeBase64 } from "@roketus/web-toolkit";
import { urlErrorCodes } from "../domain/specs/errorCodes";

export const getErrorFromQuery = (
  base64Error: string | null
): string | null => {
  if (!base64Error) return null;

  try {
    const errorString = decodeBase64(base64Error) as string;
    const [errorCode] = errorString.split(":");

    if (errorCode in urlErrorCodes)
      return urlErrorCodes[errorCode as keyof typeof urlErrorCodes];

    return null;
  } catch (e) {
    return null;
  }
};
