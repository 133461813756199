import { FC } from "react";
import { useTranslation } from "react-i18next";
import SettingsLayout from "../../components/layouts/SettingsLayout";
import ProfileOtpForm from "../../components/ProfileSettings/ProfileOtpForm";

const PhoneActivationPage: FC = () => {
  const { t } = useTranslation("translation");

  return (
    <SettingsLayout title={t("header.profileInfo") ?? ""}>
      <ProfileOtpForm />
    </SettingsLayout>
  );
};

export default PhoneActivationPage;
