import { IProfileAddressData } from "@roketus/loyalty-end-user-js-sdk";
import { format } from "date-fns";
import { DATE_FORMAT_BY_LOCALE } from "../adapters/constants";

declare global {
  interface Window {
    ReactNativeWebView?: any;
  }
}

export function clearPhoneNumber(phone: string): string {
  return phone.replace(/[\s+()]/g, "");
}

export const isIOS = (): boolean => {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
};

export const isAndroidOS = (): boolean => /Android/.test(navigator.userAgent);

export const isAndroidOSMobile = (): boolean => {
  return (
    /android/i.test(navigator.userAgent) && /mobile/i.test(navigator.userAgent)
  );
};

export const isIOSMobile = (): boolean =>
  /iPad|iPhone|iPod/.test(navigator.userAgent);

export const isWindowsMobile = (): boolean =>
  /Windows Phone/.test(navigator.userAgent);

export const isMobileDevice = (): boolean =>
  isAndroidOSMobile() || isIOSMobile() || isWindowsMobile();

export const isSafariBrowser = (): boolean =>
  /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent);

export const isSamsungBrowser = (): boolean =>
  /SamsungBrowser/.test(navigator.userAgent);

export const isTelegramWebview = () => {
  return (
    typeof (window as any).TelegramWebview !== "undefined" || // Android
    typeof (window as any).TelegramWebviewProxy !== "undefined" || // iOS
    typeof (window as any).TelegramWebviewProxyProto !== "undefined" // iOS
  );
};

export const isWebView = () => {
  const userAgent = navigator.userAgent;
  const isAndroidWebView = /wv/.test(userAgent);
  const isIOSWebView =
    /iPhone|iPod|iPad/.test(userAgent) && !/Safari/.test(userAgent);
  const isHybrid = !!window.ReactNativeWebView;
  const telegramWebview = isTelegramWebview();

  return isAndroidWebView || isIOSWebView || telegramWebview || isHybrid;
};

export const isInAppBrowser = () => {
  const isAppNamePresent =
    /fb|instagram|telegram|twitter|FBAN|FBAV|FB_IAB|messenger|WeChat|line|whatsapp/i.test(
      navigator.userAgent
    );

  return isAppNamePresent || isWebView();
};

export const isDarkThemeInBrowser = (): boolean =>
  window.matchMedia &&
  window.matchMedia("(prefers-color-scheme: dark)").matches;

export const copyText = (textToCopy: string, onCopied?: () => void) => {
  if (navigator.clipboard && navigator.clipboard.writeText) {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        onCopied && onCopied();
      })
      .catch((e) => {});
  } else {
    // for mobile devices and some old browsers
    const textArea = document.createElement("textarea");
    textArea.value = textToCopy;
    textArea.style.position = "fixed";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand("copy");
      successful && onCopied && onCopied();
    } catch (err) {}

    document.body.removeChild(textArea);
  }
};

export const getDateFormatedByLocale = (
  locale: string = "uk",
  date?: string
) => {
  if (!date) return "";

  return format(
    new Date(date),
    DATE_FORMAT_BY_LOCALE[locale as keyof typeof DATE_FORMAT_BY_LOCALE] ||
      "dd.MM.yyyy"
  );
};

export const createAddressString = (address?: IProfileAddressData): string => {
  if (!address) return "";

  const orderedKeys: Array<keyof IProfileAddressData> = [
    "city",
    "street",
    "building",
    "apartment",
  ];

  return orderedKeys
    .map((key) => address[key]?.trim())
    .filter((value) => value)
    .join(", ");
};
