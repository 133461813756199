import {
  styled,
  Box,
  Card,
  IconButton,
  IconButtonProps,
  CardProps,
  BoxProps,
} from "@mui/material";
import Slider from "react-slick";

export const CarouselContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "1000px",
  margin: "0 auto",
  // paddingBottom: '1.5rem'
}));

export const StyledCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== "bgColor" && prop !== "color",
})<CardProps & { color?: string; bgColor?: string }>(
  ({ theme, color, bgColor }) => ({
    padding: 0,
    color: color ?? theme.palette.text.primary,
    backgroundColor: bgColor ?? "#a8c9ff",
    borderRadius: "14px",
    maxWidth: 450,
    margin: "0 auto",
    "@media screen and (min-width: 600px)": {
      boxShadow: "0px 10px 20px 0px rgba(0,0,0,0.15)",
    },
  })
);

export const BarcodeContainer = styled(Box)<BoxProps>(() => ({
  borderRadius: "14px",
  backgroundColor: "rgba(255, 255, 255, 0.5)",
  padding: "1rem 1.5rem",
  margin: "0 auto",
  width: "fit-content",
}));

export const StyledSlider = styled(Slider)(({ theme }) => ({
  ".slick-list": {
    padding: "0 0 2rem",
    "@media screen and (min-width: 600px)": {
      padding: "0 0 2rem",
      marginTop: "-1rem",
      marginBottom: "-1rem",
    },
  },
  ".slick-prev:before, .slick-next:before": {
    display: "none",
  },
  ".slick-dots": {
    bottom: "-5px",
  },
  ".slick-dots li button:before": {
    fontSize: "12px",
    color: theme.palette.primary.light, // #D9D9D9

    "@media screen and (min-width: 600px)": {
      fontSize: "16px",
    },
  },
  ".slick-dots li.slick-active button:before": {
    color: theme.palette.primary.main,
    "@media screen and (min-width: 600px)": {
      fontSize: "20px",
    },
  },
}));

export const StyledSliderArrow = styled(IconButton)<
  IconButtonProps & { left: string }
>(({ theme, left }) => ({
  position: "absolute",
  top: "50%",
  left,
  transform: "translate(-100%, -50%)",
  backgroundColor: theme.palette.background.paper,
  color: "#000000",
  "&:hover, &:focus": {
    backgroundColor: theme.palette.action.hover,
    color: "inherit",
  },
  "&.slick-disabled": {
    color: "#D9D9D9",
    "&:hover": {
      color: "#D9D9D9",
    },
  },
}));
