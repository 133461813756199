import { Paper, styled } from "@mui/material";

export const StyledCard = styled(Paper)(({ theme }) => ({
	padding: theme.spacing(0, 3),
	paddingBottom: theme.spacing(2),
	boxShadow: "none",
  "@media screen and (min-width: 600px)": {
		padding: theme.spacing(1, 3),
		minHeight: 525,
		maxWidth: 450,
		margin: "0 auto",
		borderRadius: "14px",
		boxShadow: "0px 10px 20px 0px rgba(0,0,0,0.15)",
	}
}));
