import { FC } from "react";
import { useObservable } from "@roketus/web-toolkit";
import { useTranslation } from "react-i18next";
import type { IOTPFormData } from "../../../boundary/forms/IOTPFormData";
import type { TSetFormFieldError } from "../../../boundary/forms/TSetFormFieldError";
import { buildAnalyticMessageEntity } from "../../../domain/entities/analyticEntity";
import {
  getMessageBus,
  getSigninService,
} from "../../../diContainer/getDependencies";
import LayoutHeadless from "../../components/layouts/LayoutHeadless";
import { ValidateOtp } from "../../components/ValidateOtp";

const ValidateOtpPage: FC = () => {
  const { t } = useTranslation("translation");
  
  const signInService = getSigninService();
  const messageBus = getMessageBus();

  const data = useObservable(signInService.data$);
  const phoneNumber = data?.phoneNumber ?? "";

  const sendCode = (otp: string, setError: TSetFormFieldError<IOTPFormData>) => {
    const analyticEvent = buildAnalyticMessageEntity({
      action: "SendOtpButtonClick",
      source: "SignInValidateOtpPage",
    });
    messageBus.send(analyticEvent);

    signInService.validateOTP(otp, setError);
  };

  const handleResend = async () => {
    const analyticEvent = buildAnalyticMessageEntity({
      action: "ResendOtpButtonClick",
      source: "SignInValidateOtpPage",
    });
    messageBus.send(analyticEvent);

    await signInService.resendOTP(phoneNumber);
  };

  return (
    <LayoutHeadless title={t("header.validateOtp") ?? ""}>
      <ValidateOtp
        onSendOtpClick={sendCode}
        phoneNumber={phoneNumber}
        onResendActivationCode={handleResend}
        isLoading={data?.loading ?? false}
      />
    </LayoutHeadless>
  );
};

export default ValidateOtpPage;
