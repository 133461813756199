import { FC, useMemo } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import {
  ANONYMOUS_ACTIONS_PATHS,
  AnonymousActionsPaths,
} from "../../../adapters/router/actionsRoutes";
import IssuerToken, { isDelikatIssuer } from "../../../domain/specs/issuerToken";
import { buildAnalyticMessageEntity } from "../../../domain/entities/analyticEntity";
import {
  getActionService,
  getIssuerConfigService,
  getMessageBus,
  getRouterService,
  getSignupService,
} from "../../../diContainer/getDependencies";
import type { IPhoneFormData } from "../../../boundary/IPhoneFormData";
import type { IEmailFormData } from "../../../boundary/IEmailFormData";
import type { TSetFormFieldError } from "../../../boundary/forms/TSetFormFieldError";
import { getRegStartParamsFromQuery } from "../../../utils/registration";
import { StandardWelcomePage } from "../../components/actionComponents/StandardWelcomePage";
import { DemoatomicWelcomePage } from "../../components/actionComponents/demoatomic/DemoatomicWelcomePage";
import { DemoatomicCouponAction } from "../../components/actionComponents/demoatomic/DemoatomicCouponAction";
import DelicateWelcomePage from "../../components/actionComponents/delicate/DelicateWelcomePage";

export const WelcomePage: FC = () => {
  const routerService = getRouterService();
  const issuerConfigService = getIssuerConfigService();
  const actionService = getActionService();
  const signupService = getSignupService();
  const messageBus = getMessageBus();

  const location = useLocation();
  const [searchParams] = useSearchParams();
  const actionType = location.pathname.split("/").at(-1);
  const isAnonymous = ANONYMOUS_ACTIONS_PATHS.includes(
    actionType as AnonymousActionsPaths
  );
  const issuerSysName = issuerConfigService.getIssuer();

  const [analyticsMeta, regParams] = useMemo(() => {
    const { shop, campaign, templateId } = getRegStartParamsFromQuery(
      searchParams,
      actionType
    );

    const meta = {
      campaign: campaign || actionType,
      shop,
    };
    const params = {
      template_id: templateId,
      shop,
    };
    return [meta, params];
  }, [searchParams, actionType]);

  const handleSubmit = (
    phoneOrEmail?: string,
    setError?: TSetFormFieldError<IPhoneFormData> | TSetFormFieldError<IEmailFormData>
  ) => {
    const analyticEvent = buildAnalyticMessageEntity({
      action: "ApproveActionClick",
      source: "WelcomePage",
      meta: analyticsMeta,
    });
    messageBus.send(analyticEvent);

    if (isAnonymous) {
      actionService.approvePublicAction(searchParams.toString());
    } else {
      actionService.approveAction(phoneOrEmail!, regParams, setError);
    }
  };

  const handleSignupByGoogle = () => {
    // TODO goa signin with action
    const analyticEvent = buildAnalyticMessageEntity({
      action: "SignUpByGoogleButtonClick",
      source: "WelcomePage",
      meta: analyticsMeta,
    });
    messageBus.send(analyticEvent);

    actionService.setActionToStorage();

    signupService.signupByGoogle(regParams);
  };

  const handleCancel = () => {
    routerService.navigateToIssuerPath("/info");
  };

  if (issuerSysName === IssuerToken.Demoatomic) {
    if (actionType === AnonymousActionsPaths.AnonymousCoupon) {
      return (
        <DemoatomicCouponAction
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      );
    }

    return (
      <DemoatomicWelcomePage
        isAnonymous={isAnonymous}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />
    );
  }

  if (
    isDelikatIssuer(issuerSysName) &&
    actionType === AnonymousActionsPaths.FreeSurvey
  ) {
    return (
      <DelicateWelcomePage onSubmit={handleSubmit} onCancel={handleCancel} />
    );
  }

  return (
    <StandardWelcomePage
      isAnonymous={isAnonymous}
      signupByGoogle={handleSignupByGoogle}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
    />
  );
};
