import { useMemo } from "react";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import {
  Controller,
  type Control,
  type FieldValues,
  type Path,
  type PathValue,
  type RegisterOptions,
} from "react-hook-form";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { type TextFieldProps } from "@mui/material/TextField";
import { enUS, ukUA } from "@mui/x-date-pickers/locales";
import enLocale from "date-fns/locale/en-US";
import ukLocale from "date-fns/locale/uk";
import ltLocale from "date-fns/locale/lt";

const locales = {
  en: {
    localeText: enUS.components.MuiLocalizationProvider.defaultProps.localeText,
    dateFnsLocale: enLocale,
  },
  uk: {
    localeText: ukUA.components.MuiLocalizationProvider.defaultProps.localeText,
    dateFnsLocale: ukLocale,
  },
  lt: {
    localeText: enUS.components.MuiLocalizationProvider.defaultProps.localeText,
    dateFnsLocale: ltLocale,
  },
};

interface IProps<R extends FieldValues> {
  name: Path<R>;
  defaultValue?: PathValue<R, Path<R>>;
  control: Control<R>;
  rules?: Omit<
    RegisterOptions<R, any>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
}

const DateInput = <R extends FieldValues>({
  name,
  label,
  control,
  rules,
  defaultValue,
  disabled,
  ...props
}: IProps<R> & TextFieldProps) => {
  const { t: tDefault, i18n } = useTranslation("translation", {
    keyPrefix: "forms",
  });

  const { localeText, dateFnsLocale } = useMemo(
    () => locales[i18n.language as keyof typeof locales] || locales["en"],
    [i18n]
  );

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      // defaultValue={defaultValue}
      render={({ field: { value = null, ...restField }, fieldState }) => {
        const errorProps = fieldState.error
          ? {
              error: true,
              helperText: isEmpty(fieldState.error.message)
                ? tDefault(`default.${fieldState.error.type}`)
                : fieldState.error.message,
            }
          : {};

        return (
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={dateFnsLocale}
            localeText={localeText}
          >
            <DatePicker
              {...restField}
              value={value || null}
              label={label}
              disabled={disabled}
              openTo="year"
              format={tDefault("dateFormat") ?? "dd.MM.yyyy"}
              slotProps={{
                textField: {
                  ...props,
                  ...errorProps,
                  required: !!rules?.required,
                  fullWidth: true,
                  disabled,
                  sx: {
                    "& .MuiInputAdornment-root": {
                      width: "2rem",
                    },
                  },
                },
              }}
            />
          </LocalizationProvider>
        );
      }}
    />
  );
};

export default DateInput;
