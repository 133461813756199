import { Control, Controller, FieldValues } from "react-hook-form";
import { MuiOtpInput } from "mui-one-time-password-input";
import { Box, FormHelperText } from "@mui/material";
import { isUndefined } from "lodash";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";

interface IProps<T extends FieldValues> {
  control: Control<T>;
}

const StyledMuiOtpInput = styled(MuiOtpInput)`
  & .MuiOutlinedInput-notchedOutline {
    border-color: #AAA497;
  }
  & input {
    &:-webkit-autofill {
      transition: background-color 5s 3600s;
    }
  }
`;

const OtpInput = <T extends FieldValues>({ control }: IProps<T>) => {
  const { t: st } = useTranslation("translation", {
    keyPrefix: "common.errors.otp",
  });

  return (
    <Controller
      // @ts-ignore
      name="otp"
      control={control}
      rules={{
        required: st("required") ?? "",
        pattern: {
          value: /^\d*$/,
          message: st("pattern"),
        },
        minLength: {
          message: st('minLength'),
          value: 6,
        },
        maxLength: {
          message: st("maxLength"),
          value: 6,
        },
        validate: (value: string) => {
          if (isUndefined(value)) return false;

          return value.length === 6;
        },
      }}
      render={({ field, fieldState }) => {
        const errorText = fieldState.error?.message
          ? fieldState.error?.message
          : st(fieldState.error?.type ?? "unknownText");

        return (<Box>
          <StyledMuiOtpInput
            sx={{ gap: 1 }}
            {...field}
            value={field.value as unknown as string}
            length={6}
            autoFocus
            inputMode="numeric"
            TextFieldsProps={{
              type: "number",
              inputMode: "numeric",
            }}
          />
          {fieldState.invalid && (
            <FormHelperText error>
              {errorText}
            </FormHelperText>
          )}
        </Box>
      );
      }}
    />
  );
};

export default OtpInput;
