// TODO should be handled from issuer config
export const ISSUER_LOYALTY_RULES = {
  delicatedev: {
    label: "Правилами безпеки та лояльності магазину 'Делікат В2В'",
    url: "https://atomic-cold-emails.s3.us-east-2.amazonaws.com/delikat/security-rules.pdf",
  },
  delikat: {
    label: "Правилами безпеки та лояльності магазину 'Делікат В2В'",
    url: "https://atomic-cold-emails.s3.us-east-2.amazonaws.com/delikat/security-rules.pdf",
  },
};
