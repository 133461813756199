import { useEffect } from "react";
import { useForm, type FieldValues, type Path } from "react-hook-form";
import { Box } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import type { TSetFormFieldError } from "../../../boundary/forms/TSetFormFieldError";
import PhoneInput from "../form/PhoneInput";
import LoyaltyPolicy from "../form/LoyaltyPolicy";

interface IProps<T extends FieldValues> {
  loading?: boolean;
  initialValues?: T;
  hasPolicy?: boolean;
  submitLabel: string;
  onSubmit: (phone: string, setError: TSetFormFieldError<T>) => void;
}

const PhoneForm = <T extends FieldValues>({
  loading,
  initialValues,
  hasPolicy,
  submitLabel,
  onSubmit,
}: IProps<T>) => {
  const { handleSubmit, control, setError, formState, reset, setValue } = useForm<T>();

  useEffect(() => {
    if (initialValues) reset(initialValues);
  }, [initialValues, reset]);

  const setFormError = (field: Path<T> | "root", messageKey: string) => {
    setError(field as "root" | Path<T>, { type: messageKey });
  };

  const submitForm = (form: T) => {
    onSubmit(form.phone, setFormError);
  };

  return (
    <Box
      component="form"
      noValidate
      onSubmit={handleSubmit(submitForm)}
      sx={{ display: "block", width: "100%", mb: 2 }}
    >
      <PhoneInput<T>
        name={"phone" as Path<T>}
        control={control}
        initialValue={initialValues?.phone}
        setValue={setValue}
      />

      {hasPolicy && <LoyaltyPolicy control={control} />}

      <LoadingButton
        type="submit"
        variant="contained"
        onClick={handleSubmit(submitForm)}
        fullWidth
        loading={loading}
        data-testid="submit"
        disabled={!formState.isValid}
        sx={{ mt: 2 }}
      >
        {submitLabel}
      </LoadingButton>
    </Box>
  );
};

export default PhoneForm;
