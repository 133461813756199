import {
  EC_FAILED_FETCH_CONFIG_DATA,
  EC_INVALID_ISSUER_SYS_NAME,
  EC_NO_CARD,
} from "../../domain/specs/errorCodes";
import { Ii18nScheme } from "./en";

const uk: Ii18nScheme = {
  translation: {
    forms: {
      default: {
        required: "* Обов'язкове поле",
      },
      isRequired: "Поле {{label}} обов'язкове",
      minLength: "{{label}} має містити принаймні 2 символи",
      maxLength: "{{label}} має містити менше 50 символів",
      invalid: "Невірний формат",
      "incorrect first name, can contain only letters and numbers":
        "Ім’я/Прізвище не може містити цифри",
      "incorrect last name, can contain only letters and numbers":
        "Ім’я/Прізвище не може містити цифри",
      nameInvalid: "Ім’я/Прізвище не може містити цифри",
      dateFormat: "dd.MM.yyyy",
    },
    common: {
      ok: "Ok",
      yes: "Так",
      no: "Ні",
      copy: "Скопіювати",
      copiedMessage: "Посилання скопійовано!",
      next: "Далі",
      phoneInput: {
        placeholder: "Введіть номер телефону",
      },
      emailInput: {
        label: "Email",
      },
      errors: {
        "user not found":
          "Користувача не знайдено. Будь ласка, перевірте правильність введених даних",
        "wrong phone code": "Ой помилка, невірний код",
        isRequired: "{{label}} є обов'язковим",
        unknownTitle: "Помилка",
        unknownText: "Упс, щось пішло не так!",
        minLength: "Значення занадто коротке",
        phone: {
          required: "Невірний формат",
          "phone belong another user": "Номер вже зареєстровано",
          unknownText: "Упс, щось пішло не так, спробуйте ще",
          "user not found": "Користувача не знайдено. <a>Зареєструватися</a>",
          "incorrect phone": "Не коректний номер телефону",
          phoneIncorrect: "Номер телефону закороткий",
        },
        email: {
          required: "Емейл обовʼязковий",
          unknownText: "Щось пішло не так",
          "user not found": "Користувача не знайдено",
          "incorrect email": "Не корректний емейл",
          emailIncorrect: "Не корректний емейл",
        },
        otp: {
          required: `Обов'язкове поле`,
          pattern: "Невірний формат",
          minLength: "Мінімальна довжина 6 символів",
          maxLength: "Максимальна довжина 6 символів",
          "wrong phone code": "Невірний код",
          unknownText: "Упс, щось пішло не так, спробуйте ще",
        },
      },
      date: {
        month: {
          January: "Січень",
          February: "Лютий",
          March: "Березень",
          April: "Квітень",
          May: "Травень",
          June: "Червень",
          July: "Липень",
          August: "Серпень",
          September: "Вересень",
          October: "Жовтень",
          November: "Листопад",
          December: "Грудень",
        },
      },
      showAll: "Показати усі",
      hide: "Приховати",
    },
    header: {
      settings: "Налаштування",
      terms: "Умови",
      help: "Допомога",
      profileInfo: "Інформація профілю",
      signin: "Вхід",
      signup: "Реєстрація",
      validateOtp: "Будь ласка, введіть код підтвердження",
    },
    inAppBrowserWarning: {
      warningTitle: "Відкрити в браузері",
      copyMessage:
        "Будь ласка, скопіюйте це посилання та відкрийте у браузері для повноцінного користування додатком.",
      orMessage:
        "Або скористайтесь довгим натисканням на посилання в повідомленні <span>-> 'Відкрити в...'</span>",
    },
    signIn: {
      index: {
        header: "Ласкаво просимо до {{company}}!",
        text: "<bold>Увійдіть</bold>, щоб отримати доступ до всіх функцій нашої платформи",
        buttonLabel: "Приєднатись, якщо ще не з нами",
        submit: "Код підтвердження",
        signUpLabel: "Ще не маєте акаунту?",
        signUp: "Зареєструватися",
        or: "або",
        authMethod: {
          phone: "Увійти з номером телефону",
          email: "Увійти з електронною поштою",
          google: "Продовжити з Google",
        },
      },
      validateOtp: {
        text: "6 цифр, що надійшли на ваш номер телефону <span>{{phoneNumberSlice}}</span>",
        changePhoneNumber: "Редагувати номер",
        noCode: "Не приходить код?",
        resendCode: "Надіслати код ще раз",
        waitResend: "Повторно відправити можна через:",
        buttonLabel: "Підтвердити",
        notReceivingOtp: "Не приходить СМС",
      },
    },
    signUp: {
      emailConfirmed: "Вашу електронну адресу підтверджено",
      index: {
        header: "Ласкаво просимо до {{company}}!",
        text: "<bold>Зареєструйтеся</bold>, щоб отримати доступ до всіх функцій нашої платформи",
        submit: "Відправити код",
        termsAndConditionsLabel:
          "Я погоджуюсь з <lt>Умовами користування Сервісами</lt> та <lc>Політикою Конфіденційності</lc>",
        agreeToLabel: ", а також з <lt>{{label}}</lt>",
        hasAccount: "Вже маєте акаунт?",
        signIn: "Увійти",
        company: "Компанія",
        or: "або",
        authMethod: {
          phone: "Зареєструватися з номером телефону",
          email: "Зареєструватися з електронною поштою",
          google: "Продовжити з Google",
        },
      },
      phoneActivation: {
        title: "Вам вдалося!",
        header:
          "Вітаємо в програмі лояльності {{issuerTitle}} з усіма її перевагами.<br />Насолоджуйтесь широким вибором бонусів, заощаджень, знижок та інших пропозицій.",
        bonuses: "НЕЗАБАРОМ ВИ ОТРИМАЄТЕ ВАШІ БОНУСИ!",
        moreBonuses: "Заробити більше бонусів",
        bonusesProgram: "Бонусна Програма",
        complete: "Закінчіть активацію, щоб отримати більше бонусів.",
        buttonLabel: "Продовжити",
      },
      setProfile: {
        header: "Надайте інформацію про себе",
        birthHeader: "Будь ласка, введіть дату свого народження",
        genderHeader: "Будь ласка, вкажіть свою стать",
        workplaceHeader: "Будь ласка, введіть своє місце роботи",
        addressHeader: "Будь ласка, введіть свою адресу",
        firstNameLabel: "Імʼя",
        lastNameLabel: "Прізвище",
        continueButton: "Підтвердити",
        cardPreview: "Попередній вигляд карти",
        skip: "Пропустити",
      },
      vendorIntegration: {
        vendorIntegrationError: "Помилка при реєстрації",
        close: "На головну",
      },
      step: {
        Basic: "",
        Profile: "Введіть ім’я та прізвище",
        SetPhone: "",
        PhoneActivation: "",
        PhoneActivationSuccess: "",
        SetEmail: "Підтвердити електронну пошту",
        EmailActivation: "",
        SetBirthday: "Встановити дату народження",
        Completed: "Профіль",
      },
      goa: {
        successText:
          "Ваша реєстрація через обліковий запис Google підтверджена",
        submit: "Далі",
        errorText: "Щось пішло не так",
        cancel: "Закрити",
      },
    },
    checkInbox: {
      header: "Перевірте свою поштову скриньку!",
      confirmEmail: "Підтвердіть свою електронну адресу!",
    },
    profile: {
      title: "Профіль",
      mainInfo: "Основна інформація",
      company: "Компанія: {{name}}",
      organizationName: "Назва організації:",
      description: "Опис:",
      balance: "Баланс",
      userName: "Імʼя користувача",
      level: "Рівень",
      purchaseBonuse: "Бонус за покупку",
      companyName: "Компанія:",
      mainText:
        "Ваша карта лояльності {{issuerTitle}} завжди з вами. Використовуйте її для отримання переваг та знижок в {{issuerTitle}}.",
      dateLabel: "Дійсний до: ",
      downloadCardForIos: "Скачати картку для iOS",
      downloadCardForAndroid: "Скачати картку для Android",
      balanceLabel: "Баланс",
      discountLabel: "Бонус за покупку",
      cardNumberLabel: "Номер карти",
      downloadCardLabel: "Додайте карту в гаманець",
      logout: "Вийти",
      logoutConfirm: "Ви впевнені, що бажаєте вийти?",
      promoCodes: "Промокоди",
      notActivatedWarning:
        "Щоб користуватися бонусами, будь ласка, підтвердіть номер телефону.",
      activate: "Активувати",
      form: {
        profileInfo: "Інформація профілю",
        updateInfoTitle: "Оновіть свої дані",
        updatePhoneTitle: "Оновіть номер телефону",
        updateEmailTitle: "Оновіть електронну пошту",
        updateBirthHint: "Будь ласка, введіть свою дійсну Дату народження",
        updateGenderHint: "Будь ласка, введіть свої дані",
        updateWorkplaceHint: "Будь ласка, введіть своє дійсне Місце роботи",
        updateAddressHint: "Будь ласка, введіть свою дійсну Адресу",
        save: "Зберегти",
        update: "Оновити",
        fullName: "Повне імʼя",
        firstNameLabel: "Імʼя",
        lastNameLabel: "Прізвище",
        middleNameLabel: "По-батькові",
        successInfoUpdated: "Ваші дані оновлені",
        phone: "Номер телефону",
        phoneHint: "Введіть новий номер телефону",
        phoneRequired: "Необхідно вказати номер телефону",
        phoneInvalid: "Недійсний формат номера телефону",
        successPhoneUpdated: "Ваш номер телефону оновлено",
        verifyPhone:
          "Телефон не верифіковано, <button>НАТИСНИ</button> щоб виправити це",
        email: "Електронна пошта",
        emailHint: "Введіть нову електронну адресу",
        emailRequired: "Необхідно вказати адресу електронної пошти",
        emailInvalid: "Недійсний формат електронної пошти",
        successEmailUpdated: "Вашу електронну адресу оновлено",
        birthDateLabel: "Дата народження (дд.мм.рррр)",
        genderLabel: "Стать",
        gender: {
          female: "Жінка",
          male: "Чоловік",
          other: "Інше",
          secret: "Не хочу повідомляти",
        },
        workplaceLabel: "Місце роботи",
        addressLabel: "Адреса",
        regionLabel: "Область",
        districtLabel: "Район",
        cityLabel: "Населений пункт",
        streetLabel: "Вулиця",
        buildingLabel: "Будинок",
        apartmentLabel: "Квартира",
      },
    },
    settings: {
      settings: "Налаштування",
      language: "Мова",
      profileInfo: "Інформація профілю",
      terms: "Умови",
      help: "Допомога",
      logOut: "Вийти",
      back: "Назад",
    },
    changeProfile: {
      title: "Змінити ім’я та прізвище",
      buttonLabel: "Змінити",
      firstNameLabel: "Ім’я",
      lastNameLabel: "Прізвище",
    },
    changePhoneNumber: {
      title: "Змінити номер телефону",
      buttonLabel: "Змінити",
      updateButtonLabel: "Змінити {{time}}",
    },
    changeEmail: {
      title: "Змінити електронну пошту",
      buttonLabel: "Змінити",
    },
    emailVerification: {
      send: "Надіслати підтвердження",
      skip: "Пропустити",
      title: "Підтвердьте адресу електронної пошти!",
      message: "Введіть свою електронну адресу.",
      email: "Eлектронна пошта",
    },
    referralProgram: {
      referralProgram: "Реферальна програма",
      title: "Отримайте більше бонусів!",
      subtitle: "Запросіть друга та отримайте бонуси.",
      description:
        "Коли новий клієнт  створить обліковий запис та встановить картку постійного клієнта, ви та ваш друг отримаєте за це бонуси.",
    },
    promoCodes: {
      title: "Промокоди",
      promoCode: "Промокод",
      numberOfUses: "Кількість використань",
      endDate: "Кінцева дата",
      description: "Опис",
      whatIsPromo: "Що таке промокод?",
      info: "<strong>Промокод</strong> - це спеціальний код, який дає вам знижку на товар або послугу при здійсненні покупки. Промокоди пов’язані з вашою карткою програми лояльності, що дозволяє вам заощаджувати гроші на вибраних товарах або послугах",
      howToUsePromo: "Як використовувати промокод?",
      steps: {
        checkCodes: {
          title: "<bold>Перевірте свої промокоди:</bold>",
          content:
            "Відкрийте свою картку постійного клієнта в мобільному гаманці або веб-версії, щоб побачити доступні промокоди.",
        },
        atCheckout: {
          title: "<bold>На касі:</bold>",
          content: [
            "Покажіть картку касиру або вкажіть номер телефону.",
            "Касир відсканує вашу картку, і промокод буде автоматично застосовано до вашої покупки.",
          ],
        },
        discount: {
          title: "<bold>Знижка:</bold>",
          content:
            "Касир повідомить вам про знижку, і ви зможете завершити покупку з економією.",
        },
      },
    },
    action: {
      thanksForScanning: "Дякуємо за сканування QR-коду!",
      thanksForChoosing:
        "Ми щиро вдячні вам за те, що ви вирішили скористатися нашою спеціальною пропозицією!",
      valuableCustomer:
        "Ви наш найцінніший клієнт, і ваш вибір робить нас ще кращими",
      reward: "Винагорода",
      bonus: {
        reward:
          "У вас є шанс отримати 100 бонусів, які ви можете витратити в нашому магазині",
        leavePhone:
          "Будь ласка, залиште свій номер телефону та отримайте бонуси",
      },
      coupon: {
        reward:
          "У вас є шанс отримати купон, який ви можете використати у нашому магазині",
        leavePhone: "Будь ласка, залиште свій номер телефону та отримайте його",
      },
      promo: {
        reward:
          "У вас є шанс отримати промо код, який ви можете використати у нашому магазині",
        leavePhone: "Будь ласка, залиште свій номер телефону та отримайте його",
      },
      notification: {
        reward:
          "Ви маєте шанс отримати унікальну пропозицію від нашого магазину",
        leavePhone: "Будь ласка, залиште свій номер телефону та отримайте її",
      },
      survey: {
        reward:
          "Ви маєте шанс отримати 100 бонусів, які ви можете витратити в нашому магазині після проходження опитування",
        leavePhone:
          "Будь ласка, залиште свій номер телефону, щоб отримати бонуси",
      },
      surveyForm: {
        question: "Вам подобається у нас?",
        yes: "Так",
        no: "Ні",
        next: "Далі",
      },
      dispatchResult: {
        successHeader: "Вітаємо!",
        failHeader: "Щось пішло не так",
        sorryHeader: "Вибачте",
        warning: "Увага!",
        successBonusText:
          "Вам буде нараховано бонусні бали<br />*бали будуть активовані відповідно до правил ПЛ",
        successCouponText: "Ви отримали купон",
        successPromoText: "Ви отримаєте промо код",
        successNotificationText: "Ви отримаєте спеціальну пропозицію",
        failBonusText: "Бонуси не нараховані",
        failCouponText: "Не вдалося відправити купон",
        failNotificationText: "Неможливо відправити повідомлення",
        failPromoText: "Не вдалося відправити промо код",
        unknownErrorText: "Пропозиція вже активована",
        promoCodeNotFound: "Такого промо кода не знайдено",
        alreadyUsedBonuses: "Ви вже скористалися даною пропозицією",
        alreadyAddedPromoCode: "Промокод вже додано",
        alreadyUsedPromoCode: "Промокод на подарунок вже було використано",
        promoCodeExpired: "Акційна пропозиція більше недоступна",
        promoCodeNotAvailable: "Акційна пропозиція недоступна",
        toMainPage: "На головну",
        close: "Закрити",
        pleaseWait: "Будь-ласка, зачекайте",
      },
      demoatomic: {
        bonusTitle: "<span>Дякуємо</span> за сканування QR коду!",
        surveyTitle:
          "Будь ласка, дайте відповідь на <span>одне питання</span>. Вам сподобалося в нашому магазині?",
        qrTitle: "<span>Тільки сьогодні!</span> Ексклюзивна пропозиція",
        couponTitle:
          "<span>Тільки сьогодні!</span> Приєднуйся та отримуй ексклюзивні знижки й бонуси вже зараз!",
        successTitle: "<span>Дякуємо</span> за Ваш час та взаємодію",
        errorTitle: "<span>Ой!</span> Щось пішло не так",
        sorryTitle: "<span>Вибачте!</span><br/>Побачимось згодом!",
        bonusText:
          "Ми щиро вдячні вам за те, що ви вирішили скористатися нашою <b>спеціальною пропозицією</b>! Ви наш найцінніший клієнт, і ваш вибір робить нас ще кращими. Ви маєте можливість скористатися перевагами нашої <b>програми лояльності</b>",
        phoneLabel:
          "Будь ласка, залиште свій номер телефону, щоб скористатися цією можливістю",
        surveyQuestion: "Вам сподобалося в нашому магазині?",
        surveyYes: "Мені дуже сподобалось",
        surveyNo: "Могло б бути краще",
        homeButton: "На головну",
        close: "Відміна",
        confirm: "Далі",
      },
    },
    landing: {
      title: "Інформація про товар",
      text: "Наші товари найкращі серед усіх товарів",
    },
    welcomePage: {
      ok: "Далі",
      cancel: "Відміна",
    },
    infoPage: {
      message: "Вибачте.<br />До зустрічі!",
      notFound: "Вибачте.<br />Сторінка не знайдена",
      googleAuthError: "Сталася помилка під час спроби входу через Google",
      alreadyRegisteredGoa:
        "Користувач із таким електронним адресом вже зареєстрований.",
      userNotFound:
        "Ми не змогли увійти через Google, оскільки немає акаунта, пов'язаного з цією електронною поштою.<br /><br /> Будь ласка, зареєструйте новий акаунт або спробуйте увійти іншим способом.",
      ok: "Назад",
      toLogin: "Увійти",
      toSignup: "Зареєструватися",
    },
    footer: {
      help: "Допомога",
    },
    errorPage: {
      [EC_FAILED_FETCH_CONFIG_DATA]: "Не вдалося отримати конфігурацію",
      [EC_INVALID_ISSUER_SYS_NAME]:
        "Щось не так із посиланням, будь ласка, скануйте ще раз",
      [EC_NO_CARD]: "Не знайдено картку Loyalty",
      unknownTitle: "Oops!",
      unknownText:
        "Програма лояльності, до якої ви намагаєтесь отримати доступ, ще не запущена, або URL-адреса неправильна. Слідкуйте за оновленнями!",
      actionText: "На головну",
    },
  },
};

export default uk;
