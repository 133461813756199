import { FC } from "react";
import { useTranslation } from "react-i18next";
import LoadingButton from "@mui/lab/LoadingButton";
import { Button, Grid2 as Grid } from "@mui/material";
import Arrow from "../../../icons/Arrow";
import styles from "./styles.module.scss";

interface IProps {
	confirmType?: "submit" | "button";
  disabled?: boolean;
	loading?: boolean;
  handleConfirm: () => void;
  handleCancel: () => void;
}

const ActionButtonGroup: FC<IProps> = ({
	confirmType = "submit",
  disabled,
	loading,
  handleConfirm,
  handleCancel,
}) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "action.demoatomic",
  });

  return (
    <Grid
      size={12}
			className={styles.buttonGroup}
    >
      <Button fullWidth className={styles.buttonCancel} onClick={handleCancel}>
        {t("close")}
      </Button>
      <LoadingButton
        type={confirmType}
        fullWidth
        className={styles.buttonConfirm}
        disabled={disabled}
				loading={loading}
        onClick={handleConfirm}
      >
        {t("confirm")}
        <Arrow />
      </LoadingButton>
    </Grid>
  );
};

export default ActionButtonGroup;
