import { useParams } from "react-router-dom";
import IssuerToken, { isDelikatIssuer } from "../../domain/specs/issuerToken";
import { PositiveRetailateResult } from "../components/retailate/PositiveRetailateResult";
import DemoatomicActionSuccess from "../components/actionComponents/demoatomic/DemoatomicActionSuccess";
import { RegisterCompanySuccess } from "../components/actionComponents/delicate/RegisterCompanySuccess";
import { NotFound } from "../components/NotFound";

const SuccessPage: React.FC = () => {
  const { issuerSysName } = useParams();

  if (issuerSysName?.toLowerCase() === IssuerToken.Retailete) {
    return <PositiveRetailateResult />;
  }

  if (issuerSysName?.toLowerCase() === IssuerToken.Demoatomic) {
    return <DemoatomicActionSuccess />;
  }

  if (isDelikatIssuer(issuerSysName?.toLowerCase())) {
    return <RegisterCompanySuccess />;
  }

  return <NotFound />;
};

export default SuccessPage;
