import { FC } from "react";
import { isEmpty } from "lodash";
import { Trans, useTranslation } from "react-i18next";
import { Path, useForm } from "react-hook-form";
import { useObservable } from "@roketus/web-toolkit";
import { Box, Grid2 as Grid } from "@mui/material";
import { getActionService } from "../../../../diContainer/getDependencies";
import { useIsAuthenticated } from "../../../../adapters/hooks/useIsAuthenticated";
import { isValidPhoneNumber } from "../../../../adapters/helpers";
import type { IPhoneForm } from "../../../../boundary/forms/IProfileForm";
import type { TSetFormFieldError } from "../../../../boundary/forms/TSetFormFieldError";
import qrIcon from "../../../icons/qr.svg";
import DemoatomicLayout from "./DemoatomicLayout";
import PhoneInput from "../../form/PhoneInput";
import welcomeImage from "./images/welcome-bg.svg";
import ActionButtonGroup from "./ActionButtonGroup";
import styles from "./styles.module.scss";

interface IProps {
  isAnonymous: boolean;
  onSubmit: (phone: string, setError?: TSetFormFieldError<IPhoneForm>) => void;
  onCancel: () => void;
}

export const DemoatomicWelcomePage: FC<IProps> = ({
  isAnonymous,
  onSubmit,
  onCancel,
}) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "action.demoatomic",
  });

  const actionService = getActionService();

  const actionData = useObservable(actionService.data$);

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
    watch,
  } = useForm<IPhoneForm>();

  const isAuthenticated = useIsAuthenticated();

  const phoneNumberValue = watch("phone");
  const isDisabled =
    !isAnonymous &&
    (!isValidPhoneNumber(phoneNumberValue) || !isEmpty(errors)) &&
    !isAuthenticated;

  const setFormError = (
    field: Path<IPhoneForm> | "root",
    messageKey: string
  ) => {
    setError(field as "root" | Path<IPhoneForm>, { type: messageKey });
  };

  const submitForm = (formData: IPhoneForm) => {
    onSubmit(formData.phone, setFormError);
  };

  return (
    <DemoatomicLayout
      icon={qrIcon}
      title="bonusTitle"
      image={welcomeImage}
      className="welcomePage"
    >
      <>
        <p>
          <Trans
            i18nKey={"action.demoatomic.bonusText"}
            components={{ b: <b /> }}
          />
        </p>

        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit(submitForm)}
          sx={{ mt: 3, display: "block", width: "100%" }}
        >
          <Grid container spacing={2}>
            {!isAnonymous && !isAuthenticated && (
              <Grid size={12}>
                <label className={styles.phoneLabel}>{t("phoneLabel")}</label>
                <PhoneInput<IPhoneForm>
                  name="phone"
                  className={styles.phoneInput}
                  control={control}
                  label={""}
                  fullWidth
                />
              </Grid>
            )}

            <ActionButtonGroup
              disabled={isDisabled || actionData?.loading}
              loading={actionData?.loading}
              handleConfirm={handleSubmit(submitForm)}
              handleCancel={onCancel}
            />
          </Grid>
        </Box>
      </>
    </DemoatomicLayout>
  );
};
