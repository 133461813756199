import { type LoaderFunctionArgs, redirect } from "react-router-dom";
import { decodeBase64 } from "@roketus/web-toolkit";
import type { IIssuerConfigService } from "../../../boundary/IIssuerConfigService";
import type { IAuthService } from "../../../boundary/IAuthService";
import type { IActionService } from "../../../boundary/IActionService";
import { ACTION_DISPATCH_SEARCH_PARAM, ActionsPaths } from "../actionsRoutes";
import { isInAppBrowser } from "../../../utils/utils";
import { isInvalidIssuerSysName } from "../../../utils/registration";
import {
  CustomCodeError,
  EC_INVALID_ISSUER_SYS_NAME,
} from "../../../domain/specs/errorCodes";

export const createGoaTokenLoader =
  (
    issuerConfigService: IIssuerConfigService,
    authService: IAuthService,
    actionService: IActionService
  ) =>
  async ({ params, request }: LoaderFunctionArgs) => {
    if (isInAppBrowser()) {
      return null;
    }

    const issuerSysName =
      issuerConfigService.getIssuer() ?? params.issuerSysName;

    const url = new URL(request.url);
    const token = url.searchParams.get("t");
    const refreshToken = url.searchParams.get("r") ?? undefined;

    const action = actionService.restoreActionFromStorage();
    actionService.removeActionFromStorage();

    try {
      if (isInvalidIssuerSysName(issuerSysName))
        throw new CustomCodeError(EC_INVALID_ISSUER_SYS_NAME);

      if (!token) {
        const actionPath =
          action?.actionType && action?.dispatchKey
            ? `/${action.actionType}?${ACTION_DISPATCH_SEARCH_PARAM}=${action.dispatchKey}`
            : "";

        return redirect(`/${issuerSysName}/signin${actionPath}`);
      }

      const decodedToken = decodeBase64(token) as string;
      const decodedRefreshToken = refreshToken
        ? (decodeBase64(refreshToken) as string)
        : refreshToken;

      authService.setTokens(decodedToken, decodedRefreshToken);
      authService.setIsAuthenticated();

      // return to action flow if there is an action in storage
      if (action?.dispatchKey) {
        const actionPath =
          action.actionType === ActionsPaths.Survey ? "/survey" : "/action";

        return redirect(`/${issuerSysName}/${actionPath}`);
      }

      return redirect(`/${issuerSysName}`);
    } catch (e: any) {
      throw e;
    }
  };
