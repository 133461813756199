import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useObservable } from "@roketus/web-toolkit";
import { Skeleton, Typography, useMediaQuery, useTheme } from "@mui/material";
import {
  getMessageBus,
  getProfileService,
  getRouterService,
} from "../../../diContainer/getDependencies";
import { pushErrorToFaro, pushEventToFaro } from "../../../utils/faroLogs";
import { buildAnalyticMessageEntity } from "../../../domain/entities/analyticEntity";
import SectionContainer from "../common/SectionContainer";
import CopyLink from "../common/CopyLink";

const ReferralProgramBox: FC = () => {
  const { t } = useTranslation("translation", { keyPrefix: "referralProgram" });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const profileService = getProfileService();
  const routerService = getRouterService();
  const messageBus = getMessageBus();

  const profileData = useObservable(profileService.data$);

  const referralLink = useMemo(
    () => routerService.getReferralLink(),
    [routerService]
  );

  const handleCopySuccess = () => {
    pushEventToFaro("Referral link copied");

    const analyticEvent = buildAnalyticMessageEntity({
      action: "CopyReferralLinkClick",
      source: "ReferralBox",
    });
    messageBus.send(analyticEvent);
  };

  const handleCopyError = (e: any) => {
    pushErrorToFaro(e, { message: "Error: failed to copy ref link" });
  };

  if (!profileData || profileData.loading || !profileData.isLoaded) {
    return (
      <>
        <Skeleton
          animation="wave"
          variant="text"
          width={100}
          sx={{ fontSize: "1.5rem" }}
        />
        <Skeleton
          animation="wave"
          variant="rounded"
          width="100%"
          height={isMobile ? 224 : 235}
        />
      </>
    );
  }

  if (!profileData?.profile?.refCode) {
    return null;
  }

  return (
    <SectionContainer title={t("referralProgram") ?? ""}>
      <>
        <Typography variant="h3" color="primary" mb={2}>
          {t("title")}
        </Typography>
        <Typography variant="body1" mb={1}>
          {t("subtitle")}
        </Typography>
        <Typography variant="body1" mb={2}>
          {t("description")}
        </Typography>

        <CopyLink
          type="input"
          url={referralLink}
          handleSuccess={handleCopySuccess}
          handleError={handleCopyError}
        />
      </>
    </SectionContainer>
  );
};

export default ReferralProgramBox;
