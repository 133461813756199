import { FC } from "react";
import { Box } from "@mui/material";
import { createRenderBarcode } from "./barcodeHelper";
import { IBarcodeType } from "../../../../boundary/IProfileService";

interface IBarcodeProps {
  type: IBarcodeType;
  text: string;
}

export const Barcode: FC<IBarcodeProps> = ({ text, type }) => {
  const renderBarcode = createRenderBarcode({ text, type });

  return (
    <Box
      sx={{
        backgroundColor: "white",
        lineHeight: 0,
        margin: "0 auto",
        maxWidth:
          type && ["qrcode", "azteccode"].includes(type) ? "125px" : "100%",
        "@media screen and (min-width: 600px)": {
          maxWidth:
            type && ["qrcode", "azteccode"].includes(type) ? "160px" : "100%",
        },
      }}
    >
      <canvas style={{ width: "100%", maxWidth: 700 }} ref={renderBarcode} />
    </Box>
  );
};
