import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Grid2 as Grid, Typography } from "@mui/material";
import { IPromoCodeEntity } from "../../../../domain/entities/PromocodeEntity";
import { StyledPromoCard } from "./styledComponents";

interface IProps {
  promoCode: IPromoCodeEntity;
}

const PromoCodeCard: FC<IProps> = ({ promoCode }) => {
  const { t } = useTranslation("translation", { keyPrefix: "promoCodes" });

  return (
    <StyledPromoCard>
      <Grid container gap={1} justifyContent="space-between">
        <Grid size={6}>
          <Typography variant="body2">
            {t("numberOfUses")}: <b>{promoCode.countOfUses}</b>
          </Typography>
          <Typography variant="body2">
            {t("endDate")}: <b>{promoCode.validTill}</b>
          </Typography>
        </Grid>

        <Grid size={5}>
          <Typography variant="body2">
            {t("description")}: <b>{promoCode.description}</b>
          </Typography>
        </Grid>
      </Grid>

      <Typography variant="body1" fontSize={40} lineHeight={1.2} textAlign="center">
        {promoCode.name.toUpperCase()}
      </Typography>
    </StyledPromoCard>
  );
};

export default PromoCodeCard;
