import { type LoaderFunctionArgs, createBrowserRouter, Outlet } from "react-router-dom";
import { withFaroRouterInstrumentation } from "@grafana/faro-react";
import type { PersistentStorage } from "@roketus/web-toolkit";
import type { IIssuerConfigService } from "../../boundary/IIssuerConfigService";
import type { IProfileService } from "../../boundary/IProfileService";
import type { IActionService } from "../../boundary/IActionService";
import type { IAuthService } from "../../boundary/IAuthService";
import { createAuthLoader } from "./loaders/authLoader";
import { createIssuerLoader } from "./loaders/issuerLoader";
import { createGoaTokenLoader } from "./loaders/goaTokenLoader";
import { createProfileLoader } from "./loaders/profileLoader";
import { createOldRouteRedirectLoader } from "./loaders/oldRouteRedirectLoader";
import { getActionsRoutes, getAnonymousActionsRoutes } from "./actionsRoutes";

import App from "../../view/components/App";
import ErrorPage from "../../view/pages/ErrorPage/ErrorPage";
import SuccessPage from "../../view/pages/SuccessPage";
import InfoPage from "../../view/pages/InfoPage";
import LandingPage from "../../view/pages/LandingPage";
import SignUpPage from "../../view/pages/signup/SignUpPage";
import SignUpOTPPage from "../../view/pages/signup/SignUpOTPPage";
import SignUpCheckEmailPage from "../../view/pages/signup/SignUpCheckEmailPage";
import SignUpEmailConfirmedPage from "../../view/pages/signup/SignUpEmailConfirmedPage";
import SignUpGoogleSuccessPage from "../../view/pages/signup/SignUpGoogleSuccessPage";
import SignupProfilePage from "../../view/pages/signup/SignupProfilePage";
import SignUpBirthPage from "../../view/pages/signup/SignUpBirthPage";
import SignUpGenderPage from "../../view/pages/signup/SignUpGenderPage";
import SignUpWorkplacePage from "../../view/pages/signup/SignUpWorkplacePage";
import SignUpAddressPage from "../../view/pages/signup/SignUpAddressPage";
import SignUpVendorIntegrationPage from "../../view/pages/signup/SignUpVendorIntegrationPage";
import SignInPage from "../../view/pages/signin/SignInPage";
import ValidateOtpPage from "../../view/pages/signin/ValidateOtpPage";
import SignInCheckEmailPage from "../../view/pages/signin/SignInCheckEmailPage";
import SurveyActionForm from "../../view/pages/survey/SurveyActionForm";
import SurveyAnonymousForm from "../../view/pages/survey/SurveyAnonymousFormPage";
import ActionPage from "../../view/pages/action/ActionPage";
import ActionSuccessPage from "../../view/pages/action/ActionSuccessPage";
import ActionErrorPage from "../../view/pages/action/ActionErrorPage";
import ProfilePage from "../../view/pages/profile/ProfilePage";
import SettingsPage from "../../view/pages/profile/SettingsPage";
import ProfileSettingPage from "../../view/pages/profile/ProfileSettingPage";
import ProfileInfoSettingPage from "../../view/pages/profile/ProfileInfoSettingPage";
import PhoneSettingPage from "../../view/pages/profile/PhoneSettingPage";
import PhoneActivationPage from "../../view/pages/profile/PhoneActivationPage";
import EmailSettingPage from "../../view/pages/profile/EmailSettingPage";
import EmailVerificationPage from "../../view/pages/profile/EmailVerificationPage";
import LayoutHeadless from "../../view/components/layouts/LayoutHeadless";

export const createRouter = ({
  issuerConfigService,
  storage,
  profileService,
  actionService,
  authService,
}: {
  issuerConfigService: IIssuerConfigService;
  storage: PersistentStorage;
  profileService: IProfileService;
  actionService: IActionService;
  authService: IAuthService;
}) => {
  const profileLoaders = async (props: LoaderFunctionArgs) => {
    await createIssuerLoader(issuerConfigService)(props);
    return await createProfileLoader(
      issuerConfigService,
      profileService,
      authService
    )(props);
  };

  const browserRouter = createBrowserRouter([
    {
      path: "/",
      element: <App />,
      errorElement: <ErrorPage />,
      children: [
        {
          index: true,
          loader: createIssuerLoader(issuerConfigService, false),
        },

        {
          path: ":issuerSysName",
          children: [
            {
              index: true,
              element: <ProfilePage />,
              loader: profileLoaders,
            },
            {
              path: "settings",
              loader: profileLoaders,
              children: [
                {
                  index: true,
                  element: <SettingsPage />,
                },
                {
                  path: "profile",
                  children: [
                    {
                      index: true,
                      element: <ProfileSettingPage />,
                    },
                    {
                      path: ":form",
                      element: <ProfileInfoSettingPage />,
                    },
                  ],
                },
                {
                  path: "phone",
                  children: [
                    {
                      index: true,
                      element: <PhoneSettingPage />,
                    },
                    {
                      path: "activation",
                      element: <PhoneActivationPage />,
                    },
                  ],
                },
                {
                  path: "email",
                  children: [
                    {
                      index: true,
                      element: <EmailSettingPage />,
                    },
                    {
                      path: "verification",
                      element: <EmailVerificationPage />,
                    },
                  ],
                },
              ],
            },

            // SIGNIN routes
            {
              path: "signin",
              children: [
                {
                  index: true,
                  element: <SignInPage />,
                  loader: async (props) => {
                    await createIssuerLoader(issuerConfigService)(props);
                    return await createAuthLoader(
                      authService,
                      issuerConfigService,
                      true
                    )(props);
                  },
                },
                {
                  path: "check-email",
                  element: <SignInCheckEmailPage />,
                  loader: createIssuerLoader(issuerConfigService),
                },
                {
                  path: "validate-otp",
                  element: <ValidateOtpPage />,
                  loader: createIssuerLoader(issuerConfigService),
                },
                {
                  path: "goa-success",
                  loader: async (props) => {
                    await createIssuerLoader(issuerConfigService)(props);
                    return await createGoaTokenLoader(
                      issuerConfigService,
                      authService,
                      actionService,
                    )(props);
                  },
                },
              ],
            },
            {
              path: "signin", // smart-link routes with loader
              loader: async (props) => {
                await createIssuerLoader(issuerConfigService)(props);
                return await createAuthLoader(
                  authService,
                  issuerConfigService
                )(props);
              },
              children: [...getActionsRoutes(actionService)],
            },

            // SIGNUP routes
            {
              path: "signup",
              children: [
                {
                  index: true,
                  element: <SignUpPage />,
                  loader: async (props) => {
                    await createIssuerLoader(issuerConfigService)(props);
                    return await createAuthLoader(
                      authService,
                      issuerConfigService,
                      true,
                      true
                    )(props);
                  },
                },
                {
                  path: "check-email",
                  element: <SignUpCheckEmailPage />,
                  loader: createIssuerLoader(issuerConfigService),
                },
                {
                  path: "email-success",
                  element: <SignUpEmailConfirmedPage />,
                  loader: createIssuerLoader(issuerConfigService),
                },
                {
                  path: "validate-otp",
                  element: <SignUpOTPPage />,
                  loader: createIssuerLoader(issuerConfigService),
                },
                {
                  path: "goa-success",
                  element: <SignUpGoogleSuccessPage />,
                  loader: createIssuerLoader(issuerConfigService),
                },
                {
                  path: "profile",
                  element: <LayoutHeadless><Outlet /></LayoutHeadless>,
                  loader: createIssuerLoader(issuerConfigService),
                  children: [
                    {
                      index: true,
                      element: <SignupProfilePage />,
                    },
                    {
                      path: "birth",
                      element: <SignUpBirthPage />,
                    },
                    {
                      path: "gender",
                      element: <SignUpGenderPage />,
                    },
                    {
                      path: "workplace",
                      element: <SignUpWorkplacePage />,
                    },
                    {
                      path: "address",
                      element: <SignUpAddressPage />,
                    },
                  ]
                },
                {
                  path: "vendor-integration",
                  element: <SignUpVendorIntegrationPage />,
                  loader: createIssuerLoader(issuerConfigService),
                },
              ],
            },
            // smart-link routes with loader
            {
              path: "signup",
              loader: async (props) => {
                await createIssuerLoader(issuerConfigService)(props);
                return await createAuthLoader(
                  authService,
                  issuerConfigService
                )(props);
              },
              children: [...getActionsRoutes(actionService)],
            },

            {
              path: "survey",
              element: <SurveyActionForm />,
              loader: async (props) => {
                await createIssuerLoader(issuerConfigService)(props);
                return await createAuthLoader(
                  authService,
                  issuerConfigService
                )(props);
              },
            },
          ],
        },

        // Anonymous routes with issuer loader
        {
          path: ":issuerSysName",
          loader: createIssuerLoader(issuerConfigService),
          children: [
            {
              path: "landing",
              element: <LandingPage />,
            },
            {
              path: "free-survey",
              element: <SurveyAnonymousForm />,
            },
            {
              path: "success",
              element: <SuccessPage />,
            },
            {
              path: "info",
              element: <InfoPage />,
            },

            {
              path: "action",
              children: [
                {
                  index: true,
                  element: <ActionPage />,
                },
                {
                  path: "success",
                  element: <ActionSuccessPage />,
                },
                {
                  path: "info",
                  element: <ActionErrorPage />,
                },
              ],
            },
            // Anonymous smart-link routes with loader
            ...getAnonymousActionsRoutes(actionService),
          ],
        },

        // Routes for redirect from old pathes
        {
          path: "signin/:issuerSysName/*",
          loader: createOldRouteRedirectLoader(issuerConfigService),
        },
        {
          path: "signup/:issuerSysName/*",
          loader: createOldRouteRedirectLoader(issuerConfigService),
        },
      ],
    },
  ]);

  return withFaroRouterInstrumentation(browserRouter);
};

// TODO: fix custom Router interface vs React Router
// export const createRouter = withFaroRouterInstrumentation(reactBrowserRouter as Router);
