import { ThemeOptions } from "@mui/material";

const HEADER_HEIGHT = 64;
const HEADER_HEIGHT_MOBILE = 64;

export const muiThemeBaseConfig: ThemeOptions = {
  custom: {
    headerHeight: HEADER_HEIGHT_MOBILE,
    mainPadding: '1.5rem',
    linkColor: "#005CEE",
  },
  palette: {
    success: {
      main: "#33A853",
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          // height: "auto",
          height: HEADER_HEIGHT_MOBILE,
          position: "fixed",
          boxShadow: "none",
          // fontSize: 24,
          fontSize: 16,
          "@media screen and (min-width: 600px)": {
            left: '50%',
            transform: 'translateX(-50%)',
            height: HEADER_HEIGHT,
          },
          "@media screen and (min-width: 1200px)": {
            maxWidth: 1200,
          }
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          justifyContent: "flex-end",
          height: "100%",
          padding: "0 2rem",          
          [`@media (min-width:600px)`]: {
            padding: "0 1.5rem",
          },
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          marginTop: HEADER_HEIGHT_MOBILE,
          padding: "1.5rem 0 0",
          [`@media (min-width:600px)`]: {
            padding: "1rem 1.5rem",
            marginTop: HEADER_HEIGHT,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root:{
          // padding: "1.5rem",
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontSize: "inherit",
          "&.MuiButton-contained.Mui-disabled": {
            color: "#B5B1A7",
            backgroundColor: "#ECECEC",
          },
          "&.MuiButton-outlined.Mui-disabled": {
            color: "#AAA497",
            borderColor: "#AAA497",
          }
        },
        text: {
          padding: 0,
          borderRadius: 0,
          ":hover": {
            background: "transparent",
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: 0,
          borderRadius: 0,
          fontSize: "inherit",
          ":hover": {
            background: "transparent",
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          display: "none",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: 20,
          fontWeight: 700,
          textTransform: "none",
          padding: "0.875rem 0.5rem",
          "&.Mui-selected": {
            "&:first-of-type": {
              borderRadius: "0 8px 0 0",
            },
            "&:last-of-type": {
              borderRadius: "8px 0 0 0",
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: "#000000",
          borderRadius: 6,
        },
      },
    },
  },
  typography: {
    fontFamily: "Roboto, Arial, sans-serif",
    body1: {
      // fontSize: "20px",
      // lineHeight: 1.16,
      fontSize: "16px",
      lineHeight: 1.5,
    },
    body2: {
      fontSize: "12px",
      lineHeight: 1.5,
      [`@media (min-width:600px)`]: {
        fontSize: "16px",
      }
    },
    h6: {
      fontSize: "16px",
      fontWeight: 500,
    },
    h5: {
      fontSize: "20px",
      fontWeight: 500,
    },
    h4: {
      fontSize: "24px",
      fontWeight: 500,
    },
    h3: {
      fontSize: "36px",
      fontWeight: 700,
    },
    h2: {
      fontSize: "3rem",
      fontWeight: 500,
    },
    h1: {
      fontSize: "3.5rem",
      fontWeight: 500,
    },
  },
};
