import { FC } from "react";
import { useTranslation } from "react-i18next";
import { CardContent, Box, Typography } from "@mui/material";
import type { ICardInfo } from "../../../../boundary/IProfileService";
import BarcodeBox from "../BarcodeBox/BarcodeBox";
import { BarcodeContainer, StyledCard } from "./styledComponents";
import CardStrip from "./CardStrip";
import CardLogo from "./CardLogo";
import CardThumbnail from "./CardThumbnail";

interface IProps {
  cardInfo: ICardInfo | null;
}

const MainCardPreview: FC<IProps> = ({ cardInfo }) => {
  const { t } = useTranslation("translation", { keyPrefix: "profile" });

  return (
    <StyledCard color={cardInfo?.textColor} bgColor={cardInfo?.backgroundColor}>
      <CardContent>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap="0.25rem"
        >
          {cardInfo?.logoId && <CardLogo imageId={cardInfo?.logoId} />}
          <Box flex="1 1">
            <Typography variant="h6" fontSize={15}>
              {cardInfo?.logoText}
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="body1"
              fontSize={15}
              align="right"
              sx={{
                color: cardInfo?.labelColor,
              }}
            >
              {t("balance")}
            </Typography>
            <Typography variant="h6" fontSize={15} align="right">
              {cardInfo?.bonusBalance}
            </Typography>
          </Box>
        </Box>
      </CardContent>

      {cardInfo?.stripId && <CardStrip imgId={cardInfo?.stripId} />}
      {cardInfo?.thumbId && <CardThumbnail imgId={cardInfo?.thumbId} />}

      <CardContent>
        <Box display="flex" justifyContent="space-between" mb={4}>
          <Box>
            <Typography
              variant="body1"
              sx={{
                color: cardInfo?.labelColor,
              }}
            >
              {t("userName")}
            </Typography>
            <Typography variant="h6" fontSize={18}>
              {cardInfo?.fullName}
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="body1"
              align="right"
              sx={{
                color: cardInfo?.labelColor,
              }}
            >
              {t("level")}
            </Typography>
            <Typography variant="h6" fontSize={18} align="right">
              {cardInfo?.level}
            </Typography>
          </Box>
        </Box>

        <BarcodeContainer>
          <BarcodeBox loading={!cardInfo} barcodeInfo={cardInfo?.barcodeInfo} />
        </BarcodeContainer>
      </CardContent>
    </StyledCard>
  );
};

export default MainCardPreview;
