import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useObservable } from "@roketus/web-toolkit";
import { Box, Typography } from "@mui/material";
import { getProfileService } from "../../../diContainer/getDependencies";
import TextWithSkeleton from "../common/TextWithSkeleton";
import SectionContainer from "../common/SectionContainer";

const InfoRow: FC<{
  label: string;
  value?: string | number;
  mb?: number;
  isLoading?: boolean;
}> = ({ label, value, mb, isLoading }) => {
  return (
    <Box display="flex" justifyContent="space-between" mb={mb}>
      <Typography variant="body1"> {label}</Typography>
      <TextWithSkeleton
        loading={isLoading}
        value={value}
        variant="body1"
        fontWeight="bold"
        width={50}
      />
    </Box>
  );
};

const MainInfoBox: FC = () => {
  const { t } = useTranslation("translation", { keyPrefix: "profile" });

  const profileService = getProfileService();

  const profileData = useObservable(profileService.data$);

  const isLoading = !profileData || profileData.loading;

  return (
    <SectionContainer title={t("mainInfo") ?? ""}>
      <>
        <InfoRow
          label={t("organizationName")}
          value={profileData?.cardInfo?.organizationName}
          isLoading={isLoading}
          mb={1}
        />
        <InfoRow
          label={t("description")}
          value={profileData?.cardInfo?.description}
          isLoading={isLoading}
          mb={profileData?.cardInfo?.companyName ? 1 : 6}
        />
        {profileData?.cardInfo?.companyName && (
          <InfoRow
            label={t("companyName")}
            value={profileData?.cardInfo?.companyName}
            isLoading={isLoading}
            mb={6}
          />
        )}
        <InfoRow
          label={t("purchaseBonuse")}
          value={profileData?.cardInfo?.discount + "%"}
          isLoading={isLoading}
          mb={1}
        />
        <InfoRow
          label={t("cardNumberLabel")}
          value={profileData?.cardInfo?.cardNumber}
          isLoading={isLoading}
        />
      </>
    </SectionContainer>
  );
};

export default MainInfoBox;
