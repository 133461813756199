import { Trans, useTranslation } from "react-i18next";
import {
  Controller,
  type Control,
  type FieldValues,
  type Path,
} from "react-hook-form";
import { Checkbox, FormControlLabel, Typography, styled } from "@mui/material";
import { getIssuerConfigService } from "../../../diContainer/getDependencies";
import { StyledRLink } from "../../../styles/StyledRLink";
import { ISSUER_LOYALTY_RULES } from "../../../domain/specs/loyaltyRulesByIssuer";

interface IProps<T extends FieldValues> {
  name?: Path<T>;
  control: Control<T>;
}

const Icon = styled("span")(({ theme }) => ({
  borderRadius: "0.25rem",
  width: 20,
  height: 20,
  border: "1px solid",
  borderColor: theme.palette.primary.main,
}));

const IconChecked = styled(Icon)(({ theme }) => ({
  position: "relative",
  backgroundColor: theme.palette.primary.main,
  "&::after": {
    content: "''",
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "8px",
    height: "16px",
    border: "solid white",
    borderWidth: "0 2px 2px 0",
    transform: "translate(-50%, -60%) rotate(45deg)",
    transformOrigin: "center",
  },
}));

const LoyaltyPolicy = <T extends FieldValues>({
  name = "terms" as Path<T>,
  control,
}: IProps<T>): JSX.Element => {
  const { t } = useTranslation("translation", { keyPrefix: "signUp.index" });

  const issuerConfigService = getIssuerConfigService();

  const issuerSysName = issuerConfigService.getIssuer();

  const privacyPolicyLink = issuerConfigService?.getPrivacyPolicyLink();
  const termsAndConditionsLink = issuerConfigService?.getTermsLink();

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: true }}
      render={({ field }) => (
        <FormControlLabel
          control={
            <Checkbox
              {...field}
              color="primary"
              icon={<Icon />}
              checkedIcon={<IconChecked />}
              sx={(theme) => ({
                flex: "0 0 auto",
                color: theme.palette.primary.main,
              })}
            />
          }
          sx={{ alignItems: "flex-start", marginRight: 0, px: "0.2rem" }}
          label={
            <Typography
              variant="body2"
              fontSize="0.875rem"
              sx={(theme) => ({ pt: 1, color: theme.palette.text.secondary })}
            >
              <Trans
                i18nKey="termsAndConditionsLabel"
                t={t}
                components={{
                  lc: (
                    <StyledRLink
                      to={privacyPolicyLink}
                      target="_blank"
                      className="c-checkbox__link"
                    />
                  ),
                  lt: (
                    <StyledRLink
                      to={termsAndConditionsLink}
                      target="_blank"
                      className="c-checkbox__link"
                    />
                  ),
                }}
              />

              {issuerSysName && issuerSysName in ISSUER_LOYALTY_RULES && (
                <Trans
                  i18nKey="agreeToLabel"
                  t={t}
                  components={{
                    lt: (
                      <StyledRLink
                        to={
                          ISSUER_LOYALTY_RULES[
                            issuerSysName as keyof typeof ISSUER_LOYALTY_RULES
                          ].url
                        }
                        target="_blank"
                        className="c-checkbox__link"
                      />
                    ),
                  }}
                  values={{
                    label:
                      ISSUER_LOYALTY_RULES[
                        issuerSysName as keyof typeof ISSUER_LOYALTY_RULES
                      ].label,
                  }}
                />
              )}
            </Typography>
          }
        />
      )}
    />
  );
};

export default LoyaltyPolicy;
