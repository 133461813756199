enum IssuerToken {
  Retailete = "retailate",
  Demoatomic = "demoatomic",
  Delikat = "delikat",
  DelikatDev = "delicatedev",
}
export default IssuerToken;

export const isDelikatIssuer = (issuerSysName?: string | null) =>
  [IssuerToken.Delikat, IssuerToken.DelikatDev].includes(
    issuerSysName as IssuerToken
  );
