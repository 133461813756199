import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import LayoutHeadless from "../../components/layouts/LayoutHeadless";
import CheckInboxMessage from "../../components/CheckInboxMessage";
import { useNavigate, useParams } from "react-router-dom";

const EmailCheckInboxPage = () => {
  const { t } = useTranslation("translation");

  const navigate = useNavigate();
  const { issuerSysName } = useParams();

  const handleNext = () => {
    navigate(`/${issuerSysName}/signin/validate-otp`);
  };

  return (
    <LayoutHeadless title={t("header.signin") ?? ""}>
      <CheckInboxMessage />

      <Button variant="contained" fullWidth onClick={handleNext}>
        {t("common.next")}
      </Button>
    </LayoutHeadless>
  );
};

export default EmailCheckInboxPage;
