import { StateSubject } from "@roketus/web-toolkit";
import { isEmpty, isString } from "lodash";
import {
  ISignInService,
  ISignInServiceData,
} from "../../boundary/ISignInService";
import {
  EC_LOGIN_EMPTY_PHONE,
  EC_LOGIN_EMPTY_JWT,
  CustomCodeError,
} from "../../domain/specs/errorCodes";
import { pushErrorToFaro, pushLogToFaro } from "../../utils/faroLogs";
import {
  getSdk,
  getActionService,
  getAuthService,
  getIssuerConfigService,
  getProfileService,
  getRouterService,
  getSignupService,
} from "../../diContainer/getDependencies";
import { getErrorTextFromResponse } from "../../utils/response-errors";
import { AuthMethod } from "../../domain/specs/authMethods";

export const stateMachine = new StateSubject<ISignInServiceData>({
  loading: false,
  isLoaded: false,
  failed: false,
  signinMethod: AuthMethod.Phone,
});

const setSigninMethod: ISignInService["setSigninMethod"] = (signinMethod) => {
  stateMachine.setState({
    signinMethod,
  });
};

const setPhoneState: ISignInService["setPhoneState"] = (phoneNumber) => {
  stateMachine.setState({
    phoneNumber,
  });
};

const setEmailState: ISignInService["setEmailState"] = (email) => {
  stateMachine.setState({
    email,
  });
};

export const logInByPhone: ISignInService["logInByPhone"] = async (
  phoneNumber,
  setError
) => {
  pushLogToFaro("Log in: setting phone");

  stateMachine.setState({
    loading: true,
    isLoaded: false,
    failed: false,
  });

  const sdk = getSdk();
  const routerService = getRouterService();

  try {
    const phoneNumberFiltered = phoneNumber.replace(/\s+/g, "");
    await sdk.auth.loginByPhone(phoneNumberFiltered).result;

    stateMachine.setState({
      loading: false,
      isLoaded: true,
      failed: false,
      phoneNumber,
    });

    pushLogToFaro("Success: logged in by phone, waiting OTP");

    routerService.navigateToIssuerPath("/signin/validate-otp");
  } catch (error) {
    const issuerConfigService = getIssuerConfigService();
    const signupService = getSignupService();
    const issuerSysName = issuerConfigService?.getIssuer();

    pushErrorToFaro(error, {
      action: "logInByPhone",
      issuer: String(issuerSysName),
      message: "Error: failed to log in by phone",
    });

    signupService.setPhoneState(phoneNumber);

    const errorTextKey = getErrorTextFromResponse(error);

    setError("phone", errorTextKey);

    stateMachine.setState({
      loading: false,
      isLoaded: true,
      failed: true,
    });
  }
};

export const logInByEmail: ISignInService["logInByEmail"] = async (
  email,
  setError
) => {
  pushLogToFaro("Log in: setting email");

  stateMachine.setState({
    loading: true,
    isLoaded: false,
    failed: false,
  });

  // const sdk = getSdk();
  const routerService = getRouterService();

  try {
    // await sdk.auth.loginByEmail(email).result;

    stateMachine.setState({
      loading: false,
      isLoaded: true,
      failed: false,
      email,
    });

    pushLogToFaro("Success: logged in by email, waiting OTP");

    routerService.navigateToIssuerPath("/signin/validate-otp");
  } catch (error) {
    const issuerConfigService = getIssuerConfigService();
    const signupService = getSignupService();

    pushErrorToFaro(error, {
      action: "logInByEmail",
      issuer: String(issuerConfigService?.getIssuer()),
      message: "Error: failed to log in by email",
    });

    signupService.setEmailState(email);

    const errorTextKey = getErrorTextFromResponse(error);

    setError("email", errorTextKey);

    stateMachine.setState({
      loading: false,
      isLoaded: true,
      failed: true,
    });
  }
};

export const logInByGoogle: ISignInService["logInByGoogle"] = async () => {
  pushLogToFaro("Log in: by Google");

  stateMachine.setState({
    loading: true,
    isLoaded: false,
    failed: false,
  });

  const sdk = getSdk();

  try {
    const goaResult = await sdk.auth.loginByGoa().result;

    goaResult.data.url && window.open(goaResult.data.url, "_self");

    stateMachine.setState({
      loading: false,
      isLoaded: true,
      failed: false,
    });

    pushLogToFaro("Success: logged in by Google");
  } catch (error) {
    const issuerConfigService = getIssuerConfigService();

    pushErrorToFaro(error, {
      action: "logInByGoogle",
      issuer: String(issuerConfigService?.getIssuer()),
      message: "Error: failed to log in by Google",
    });

    stateMachine.setState({
      loading: false,
      isLoaded: true,
      failed: true,
    });
  }
};

export const validateOTP: ISignInService["validateOTP"] = async (
  otpCode,
  setError
) => {
  pushLogToFaro("Log in: validating OTP");

  stateMachine.setState({
    loading: true,
    isLoaded: false,
    failed: false,
  });

  const sdk = getSdk();
  const routerService = getRouterService();
  const actionService = getActionService();
  const authService = getAuthService();

  try {
    const { phoneNumber } = stateMachine.state$.value;

    if (!isString(phoneNumber) || isEmpty(phoneNumber)) {
      throw new CustomCodeError(EC_LOGIN_EMPTY_PHONE);
    }

    const { data } = await sdk.auth.loginByPhoneCheckCode(
      phoneNumber.replace(/\s+/g, ""),
      parseInt(otpCode, 10)
    ).result;

    if (!isString(data.jwt) || isEmpty(data.jwt)) {
      throw new CustomCodeError(EC_LOGIN_EMPTY_JWT);
    }

    authService.setTokens(data.jwt, data.refresh);
    authService.setIsAuthenticated();

    stateMachine.setState({
      loading: false,
      isLoaded: true,
      failed: false,
    });

    const { actionType } = actionService.data$.value;

    pushLogToFaro("Success: otp is valid, logged in", { actionType });

    routerService.navigateToInitialPage();
  } catch (error) {
    pushErrorToFaro(error, {
      action: "logInValidateOTP",
      message: "Error: failed to validate OTP on log in",
    });

    // if no phone - redirect to signin
    if (
      error instanceof CustomCodeError &&
      error.message === EC_LOGIN_EMPTY_PHONE
    ) {
      stateMachine.setState({
        loading: false,
        isLoaded: false,
        failed: false,
      });

      routerService.navigateToIssuerPath("/signin");

      return;
    }

    const errorTextKey = getErrorTextFromResponse(error);

    setError("otp", errorTextKey);

    stateMachine.setState({
      loading: false,
      isLoaded: true,
      failed: true,
    });
  }
};

export const resendOTP: ISignInService["resendOTP"] = async (phoneNumber) => {
  await logInByPhone(phoneNumber, () => {});
};

export const logOut = () => {
  const authService = getAuthService();
  const profileService = getProfileService();
  const routerService = getRouterService();

  pushLogToFaro("User logged out");

  authService.removeTokens();
  profileService.clearState();

  routerService.navigateToIssuerPath("/signin");
};

export function init(): ISignInService {
  return {
    data$: stateMachine.state$,
    setSigninMethod,
    setPhoneState,
    setEmailState,
    logInByPhone,
    logInByEmail,
    logInByGoogle,
    validateOTP,
    resendOTP,
    logOut,
  };
}
