import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useObservable } from "@roketus/web-toolkit";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import {
  getActionService,
  getRouterService,
} from "../../../diContainer/getDependencies";
import { ActionsPaths } from "../../../adapters/router/actionsRoutes";
import { ACTIONS_WITH_DOWNLOAD_RESPONSE_TYPE } from "../../../adapters/services/actionService";
import { isIOS } from "../../../utils/utils";
import { getActionUnknownErrorContent } from "../../../utils/action-results";
import FailDispatchIcon from "@mui/icons-material/NewReleases";
import LayoutHeadless from "../../components/layouts/LayoutHeadless";

const isIOSSystem = isIOS();

const ActionPage: React.FC = () => {
  const location = useLocation();

  const { isAnonymous } = useMemo(
    () => location?.state || {},
    [location]
  );

  const { t } = useTranslation("translation", {
    keyPrefix: "action.dispatchResult",
  });

  const isActionDispatched = useRef(false);

  const [isDisabled, setIsDisabled] = useState(false);

  const actionService = getActionService();
  const routerService = getRouterService();

  const actionData = useObservable(actionService.data$);

  const { titleKey, textKey, buttonTextKey } = getActionUnknownErrorContent();

  useEffect(() => {
    if (actionData?.dispatchKey && !isActionDispatched.current) {
      if (isAnonymous) {
        actionService.dispatchPublicAction();
      } else {
        isIOSSystem &&
        ACTIONS_WITH_DOWNLOAD_RESPONSE_TYPE.includes(
          actionData?.actionType as ActionsPaths
        )
          ? actionService.dispatchIOSCardAction()
          : actionService.dispatchAction();
      }

      isActionDispatched.current = true;
    }
  }, [actionData, actionService, isAnonymous]);

  const handleClose = () => {
    actionService.clearAction();
    setIsDisabled(true);
    routerService.navigateToIssuerPath("/");
  };

  return (
    <LayoutHeadless>
      {!actionData || actionData?.loading || isDisabled ? (
        <>
          <Typography gutterBottom>{t("pleaseWait")}</Typography>
          <CircularProgress />
        </>
      ) : (
        <>
          <Box>
            <FailDispatchIcon sx={{ fontSize: "50px" }} />
          </Box>
          <Typography variant="h6" gutterBottom align="center">
            {t(titleKey)}
          </Typography>
          <Typography gutterBottom align="center">
            <Trans i18nKey={textKey} t={t} components={{ br: <br /> }} />
          </Typography>
        </>
      )}

      <Button
        variant="contained"
        onClick={handleClose}
        sx={{ mt: "20px" }}
        disabled={actionData?.loading || isDisabled}
      >
        {t(buttonTextKey)}
      </Button>
    </LayoutHeadless>
  );
};

export default ActionPage;
