import { useTranslation } from "react-i18next";
import { Typography, useTheme, useMediaQuery } from "@mui/material";
import { getCardService } from "../../../../diContainer/getDependencies";
import { AndroidWalletButton } from "./AndroidWalletButton/AndroidWalletButton";
import { AppleWalletButton } from "./AppleWalletButton/AppleWalletButton";
import { StyledDownloadCardBox } from "./styledComponents";

const DownloadCardBox = () => {
  const { t } = useTranslation("translation", { keyPrefix: "profile" });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const cardService = getCardService();

  return (
    <StyledDownloadCardBox>
      {isMobile && (
        <Typography variant="h4" gutterBottom>
          {t("downloadCardLabel")}
        </Typography>
      )}
      <AndroidWalletButton onClick={cardService.downloadAndroidCard} />
      <AppleWalletButton onClick={cardService.downloadIOSCard} />
    </StyledDownloadCardBox>
  );
};

export default DownloadCardBox;
