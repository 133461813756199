import {
  EC_FAILED_FETCH_CONFIG_DATA,
  EC_INVALID_ISSUER_SYS_NAME,
  EC_NO_CARD,
} from "../../domain/specs/errorCodes";
import { Ii18nScheme } from "./en";

const lt: Ii18nScheme = {
  translation: {
    forms: {
      default: {
        required: "* Privalomas laukelis",
      },
      isRequired: "{{label}} yra būtinas",
      minLength: "{{label}} turi būti bent 2 simbolių ilgio",
      maxLength: "{{label}} turi būti trumpesnis nei 50 simbolių",
      invalid: "Netinkamas {{label}}",
      "incorrect first name, can contain only letters and numbers":
        "Varde/Pavardėje negali būti skaičių",
      "incorrect last name, can contain only letters and numbers":
        "Varde/Pavardėje negali būti skaičių",
      nameInvalid: "Varde/Pavardėje negali būti skaičių",
      dateFormat: "dd/MM/yyyy",
    },
    common: {
      ok: "Ok",
      yes: "Taip",
      no: "Ne",
      copy: "Kopijuoti",
      copiedMessage: "Nuoroda nukopijuota!",
      next: "Next",
      phoneInput: {
        placeholder: "Įveskite telefono numerį",
      },
      emailInput: {
        label: "El. paštas",
      },
      errors: {
        "user not found": "Vartotojas nerastas",
        "wrong phone code": "Neteisingas telefono kodas",
        isRequired: "{{label}} yra privaloma",
        unknownTitle: "Klaida",
        unknownText: "Ups, kažkas nepavyko",
        minLength: "Įvesties vertė yra per trumpa",
        phone: {
          required: "Negaliojantis telefono numeris",
          "phone belong another user": "Telefono numeris jau užregistruotas",
          unknownText: "Oops, kažkas nepavyko, pabandykite dar kartą vėliau",
          "user not found": "Naudotojas nerastas. <a>Registruotis</a>",
          "incorrect phone": "Neteisingas telefono numeris",
          phoneIncorrect: "Telefono numeris per trumpas",
        },
        email: {
          required: "El. paštas yra privalomas",
          unknownText: "Oi, kažkas nepavyko, bandykite dar kartą vėliau",
          "user not found": "Vartotojas nerastas",
          "incorrect email": "Neteisingas el. paštas",
          emailIncorrect: "Neteisingas el. paštas",
        },
        otp: {
          required: "Reikalingas otp",
          pattern: "Leidžiama naudoti tik skaičius",
          minLength: "Min. ilgis 6 simboliai",
          maxLength: "Max. ilgis 6 simboliai",
          "wrong phone code": "Neteisingas telefono kodas",
          unknownText: "Oops, kažkas nepavyko, pabandykite dar kartą vėliau",
        },
      },
      date: {
        month: {
          January: "Sausis",
          February: "Vasaris",
          March: "Kovas",
          April: "Balandis",
          May: "Gegužė",
          June: "Birželis",
          July: "Liepa",
          August: "Rugpjūtis",
          September: "Rugsėjis",
          October: "Spalis",
          November: "Lapkritis",
          December: "Gruodis",
        },
      },
      showAll: "Rodyti visus",
      hide: "Paslėpti",
    },
    header: {
      settings: "Nustatymai",
      terms: "Sąlygos",
      help: "Pagalba",
      profileInfo: "Profilio informacija",
      signin: "Prisijungti",
      signup: "Registruotis",
      validateOtp: "Įveskite patvirtinimo kodą",
    },
    inAppBrowserWarning: {
      warningTitle: "Atidaryti naršyklėje",
      copyMessage:
        "Nukopijuokite šią nuorodą ir atidarykite ją naršyklėje, kad visiškai mėgautumėtės programėle.",
      orMessage:
        "Arba ilgai paspauskite nuorodą pranešime <span>-> 'Atidaryti su...'</span>",
    },
    signIn: {
      index: {
        header: "Sveiki atvykę į {{company}}!",
        text: "<bold>Prisijunkite</bold>, kad galėtumėte naudotis visomis mūsų platformos funkcijomis",
        buttonLabel: "Užsiregistruoti",
        submit: "Siųsti patvirtinimo kodą",
        signUpLabel: "Neturite paskyros?",
        signUp: "Registruotis",
        or: "arba",
        authMethod: {
          phone: "Prisijungti naudojant telefono numerį",
          email: "Prisijungti naudojant el. paštą",
          google: "Tęsti su Google",
        },
      },
      validateOtp: {
        text: "Įveskite 6 skaitmenų kodą, kurį atsiuntėme į <span>{{phoneNumberSlice}}</span>",
        changePhoneNumber: "Redaguoti telefono numerį",
        noCode: "Negavote patvirtinimo kodo?",
        resendCode: "Siųsti kodą iš naujo",
        waitResend: "Galite išsiųsti kodą iš naujo po:",
        buttonLabel: "Patvirtinti kodą",
        notReceivingOtp: "Negavote OTP?",
      },
    },
    signUp: {
      emailConfirmed: "Jūsų el. pašto adresas buvo patvirtintas",
      index: {
        header: "Sveiki atvykę į {{company}}!",
        text: "<bold>Užsiregistruokite</bold>, kad galėtumėte naudotis visomis mūsų platformos funkcijomis",
        submit: "Siųsti kodą",
        termsAndConditionsLabel:
          "Sutinku su <lt>SĄLYGOMIS</lt> IR <lc>PRIVATUMO POLITIKA</lc>",
        agreeToLabel: ", ir taip pat su <lt>{{label}}</lt>",
        hasAccount: "Jau turite paskyrą?",
        signIn: "Prisijungti",
        company: "Įmonė",
        or: "arba",
        authMethod: {
          phone: "Registruotis naudojant telefono numerį",
          email: "Registruotis naudojant el. paštą",
          google: "Tęsti su Google",
        },
      },
      phoneActivation: {
        title: "Prisijungėte!",
        header:
          "Sveiki atvykę į {{{issuerTitle}} lojalumo programą ir visus jos privalumus.<br />Džiaukitės įvairiais uždarbiais, pasiūlymais ir dar daugiau.",
        bonuses: "NETRUKUS GAUSITE SAVO PREMIJAS!",
        moreBonuses: "Gaukite daugiau premijų",
        bonusesProgram: "Premijų programa",
        complete: "Užbaikite aktyvavimą, kad pridėtumėte daugiau premijų",
        buttonLabel: "Tęsti",
      },
      setProfile: {
        header: "Įveskite savo vardą",
        birthHeader: "Prašome įvesti savo gimimo datą",
        genderHeader: "Prašome įvesti savo lytį",
        workplaceHeader: "Prašome įvesti savo darbovietę",
        addressHeader: "Prašome įvesti savo adresą",
        firstNameLabel: "Vardas",
        lastNameLabel: "Pavardė",
        continueButton: "Tęsti",
        cardPreview: "Kortelės peržiūra",
        skip: "Praleisti",
      },
      vendorIntegration: {
        vendorIntegrationError: "Prisiregistravimo integracija nepavyko",
        close: "Close",
      },
      step: {
        Basic: "",
        Profile: "Nustatyti profilį",
        SetPhone: "",
        PhoneActivation: "",
        PhoneActivationSuccess: "",
        SetEmail: "Patvirtinkite savo el. paštą",
        EmailActivation: "",
        SetBirthday: "Nustatyti gimtadienį",
        Completed: "Profilis",
      },
      goa: {
        successText: "Jūsų registracija per Google paskyrą patvirtinta",
        submit: "Toliau",
        errorText: "Kažkas nepavyko",
        cancel: "Atšaukti",
      },
    },
    checkInbox: {
      header: "Patikrinkite savo pašto dėžutę!",
      confirmEmail: "Patvirtinkite savo el. paštą!",
    },
    profile: {
      title: "Jūsų paskyra",
      mainInfo: "Pagrindinė informacija",
      company: "Įmonė: {{name}}",
      organizationName: "Organizacijos pavadinimas:",
      description: "Aprašymas:",
      balance: "Balansas",
      userName: "Naudotojo vardas",
      level: "Lygis",
      purchaseBonuse: "Pirkimo premijos",
      companyName: "Kompanija:",
      mainText:
        "Tai jūsų {{issuerTitle}} lojalumo programos paskyra, kurioje galite pasitikrinti likutį, pakviesti draugus ir tvarkyti asmeninę informaciją.",
      dateLabel: "Galioja iki: ",
      downloadCardForIos: "Atsisiųsti iOS kortelę",
      downloadCardForAndroid: "Atsisiųsti Android kortelę",
      balanceLabel: "Likutis",
      discountLabel: "Pirkimo premija",
      cardNumberLabel: "Kortelės numeris",
      downloadCardLabel: "Atsisiųsti skaitmeninę kortelę",
      logout: "Atsijungti",
      logoutConfirm: "Ar tikrai norite išeiti?",
      promoCodes: "Promo kodai",
      notActivatedWarning:
        "Jei norite pasinaudoti premijomis, patvirtinkite savo telefono numerį.",
      activate: "Aktyvuoti",
      form: {
        profileInfo: "Profilio informacija",
        updateInfoTitle: "Atnaujinkite savo informaciją",
        updatePhoneTitle: "Atnaujinkite telefono numerį",
        updateEmailTitle: "Atnaujinkite el. pašto adresą",
        updateBirthHint: "Prašome įvesti savo galiojančią gimimo datą",
        updateGenderHint: "Prašome pateikti savo duomenis",
        updateWorkplaceHint: "Prašome įvesti savo galiojančią darbo vietą",
        updateAddressHint: "Prašome įvesti savo galiojantį adresą",
        save: "Išsaugoti",
        update: "Atnaujinti",
        fullName: "Pilnas vardas",
        firstNameLabel: "Vardas",
        lastNameLabel: "Pavardė",
        middleNameLabel: "Vidurinis vardas",
        successInfoUpdated: "Jūsų informacija buvo atnaujinta",
        phone: "Telefono numeris",
        phoneHint: "Įveskite naują telefono numerį",
        phoneRequired: "Telefono numeris yra būtinas",
        phoneInvalid: "Netinkamas telefono numerio formatas",
        successPhoneUpdated: "Jūsų telefono numeris buvo atnaujintas",
        verifyPhone:
          "Telefono numeris nepatvirtintas, <button>SPAUSKITE</button>, kad pataisytumėte",
        email: "El. paštas",
        emailHint: "Įveskite naują el. pašto adresą",
        emailRequired: "El. paštas yra būtinas",
        emailInvalid: "Netinkamas el. pašto formatas",
        successEmailUpdated: "Jūsų el. pašto adresas buvo atnaujintas",
        birthDateLabel: "Gimimo data (dd/mm/yyyy)",
        genderLabel: "Lytis",
        gender: {
          female: "Moteris",
          male: "Vyras",
          other: "Kita",
          secret: "Nenoriu atskleisti",
        },
        workplaceLabel: "Darbo vieta",
        addressLabel: "Adresu",
        regionLabel: "Regionas",
        districtLabel: "Rajonas",
        cityLabel: "Vietovė",
        streetLabel: "Gatvė",
        buildingLabel: "Namas",
        apartmentLabel: "Butas",
      },
    },
    settings: {
      settings: "Nustatymai",
      language: "Kalba",
      profileInfo: "Profilio informacija",
      terms: "Sąlygos",
      help: "Pagalba",
      logOut: "Atsijungti",
      back: "Atgal",
    },
    changeProfile: {
      title: "Atnaujinti asmeninę informaciją",
      buttonLabel: "Atnaujinti",
      firstNameLabel: "Vardas",
      lastNameLabel: "Pavardė",
    },
    changePhoneNumber: {
      title: "Atnaujinkite savo mobiliojo telefono numerį",
      buttonLabel: "Atnaujinti",
      updateButtonLabel: "Atnaujinti {{time}}",
    },
    changeEmail: {
      title: "Atnaujinti el. paštą",
      buttonLabel: "Atnaujinti",
    },
    emailVerification: {
      send: "Siųsti patvirtinimą",
      skip: "Praleisti",
      title: "Patvirtinkite el. pašto adresą!",
      message: "Įveskite galiojantį el. pašto adresą.",
      email: "El. paštas",
    },
    referralProgram: {
      referralProgram: "Rekomendacijų programa",
      title: "Gaukite daugiau premijų!",
      subtitle: "Pakvieskite draugą ir uždirbkite premijas.",
      description:
        "Kai naujas klientas įdiegs lojalumo kortelę ir sukurs naują paskyrą, jūs ir jūsų draugas gausite už tai premijas.",
    },
    promoCodes: {
      title: "Reklamos kodai",
      promoCode: "Reklamos kodas",
      numberOfUses: "Naudojimo skaičius",
      endDate: "Galiojimo data",
      description: "Aprašymas",
      whatIsPromo: "Kas yra reklamos kodas?",
      info: "<strong>Reklamos kodas</strong> — tai specialus kodas, suteikiantis nuolaidą prekei ar paslaugai perkant. Reklamos kodai susieti su jūsų lojalumo kortele, leidžiant taupyti pasirinktoms prekėms ar paslaugoms.",
      howToUsePromo: "Kaip naudoti reklamos kodą?",
      steps: {
        checkCodes: {
          title: "<bold>Patikrinkite reklamos kodus:</bold>",
          content:
            "Atidarykite savo lojalumo kortelę mobiliajame piniginėje arba internetinėje versijoje, kad matytumėte galimus reklamos kodus.",
        },
        atCheckout: {
          title: "<bold>Kasoje:</bold>",
          content: [
            "Parodykite kortelę kasininkui arba pateikite savo telefono numerį.",
            "Kasininkas nuskaitys kortelę, ir reklamos kodas automatiškai bus pritaikytas jūsų pirkiniui.",
          ],
        },
        discount: {
          title: "<bold>Nuolaida:</bold>",
          content:
            "Kasininkas informuos jus apie nuolaidą, ir jūs galėsite užbaigti pirkimą sutaupydami.",
        },
      },
    },
    action: {
      thanksForScanning: "Ačiū, kad nuskaitėte QR kodą!",
      thanksForChoosing:
        "Nuoširdžiai dėkojame, kad nusprendėte pasinaudoti mūsų specialiu pasiūlymu!",
      valuableCustomer:
        "Jūs esate mūsų svarbiausi klientai, o jūsų pasirinkimas daro mus dar geresnius",
      reward: "Atlygis",
      bonus: {
        reward:
          "Turite galimybę gauti 100 premijų, kurias galėsite išleisti mūsų parduotuvėje",
        leavePhone: "Norėdami gauti premijas, palikite savo telefono numerį",
      },
      coupon: {
        reward:
          "Turite galimybę gauti kuponą, kurį galėsite panaudoti mūsų parduotuvėje",
        leavePhone: "Palikite savo telefono numerį ir jį gaukite",
      },
      promo: {
        reward:
          "Turite galimybę gauti reklaminį kodą, kurį galėsite panaudoti mūsų parduotuvėje",
        leavePhone: "Palikite savo telefono numerį ir jį gaukite",
      },
      notification: {
        reward: "Turite galimybę gauti unikalų pasiūlymą iš mūsų parduotuvės",
        leavePhone: "Palikite savo telefono numerį ir jį gaukite",
      },
      survey: {
        reward:
          "Užpildę apklausą turite galimybę gauti 100 premijų, kurias galėsite išleisti mūsų parduotuvėje",
        leavePhone: "Palikite savo telefono numerį ir jas gaukite",
      },
      surveyForm: {
        question: "Ar jums patinka mūsų parduotuvė?",
        yes: "Taip",
        no: "Ne",
        next: "Toliau",
      },
      dispatchResult: {
        successHeader: "Sveikiname!",
        failHeader: "Kažkas nepavyko",
        sorryHeader: "Atsiprašome",
        warning: "ĮSPĖJIMAS!",
        successBonusText:
          "Jums bus skirti premijiniai taškai<br />*premijiniai taškai bus aktyvuoti pagal LP taisykles",
        successCouponText: "Gavote pasiūlymą",
        successPromoText: "Gausite promo kodą",
        successNotificationText: "Gausite pasiūlymą",
        failBonusText: "Premijos nebuvo skirtos",
        failCouponText: "Nepavyko išsiųsti kupono",
        failNotificationText: "Nepavyko išsiųsti pranešimo",
        failPromoText: "Nepavyko išsiųsti promo kodo",
        unknownErrorText: "Pasiūlymas jau aktyvuotas",
        promoCodeNotFound: "Reklamos kredito kodo rasti nepavyko",
        alreadyUsedBonuses: "Premijos jau panaudotos",
        alreadyAddedPromoCode: "Reklaminis pasiūlymas jau pridėtas",
        alreadyUsedPromoCode: "Dovanos akcijos kodas jau panaudotas",
        promoCodeExpired: "Reklaminis pasiūlymas nebepasiekiamas",
        promoCodeNotAvailable: "Reklaminis pasiūlymas negalimas",
        toMainPage: "Eiti į pagrindinį puslapį",
        close: "Uždaryti",
        pleaseWait: "Prašome palaukti",
      },
      demoatomic: {
        bonusTitle: "<span>Ačiū</span>, kad nuskenavote QR kodą!",
        surveyTitle:
          "Atsakykite į <span>vieną klausimą</span>. Ar jums patiko mūsų parduotuvė?",
        qrTitle: "<span>Tik šiandien!</span> Išskirtinis pasiūlymas",
        couponTitle:
          "<span>Tik šiandien!</span> Prisijunkite dabar ir gaukite išskirtines nuolaidas bei premijas!",
        successTitle: "<span>Ačiū</span> už jūsų laiką ir bendradarbiavimą",
        errorTitle: "<span>Oi!</span> Kažkas nepavyko",
        sorryTitle: "<span>Atsiprašome!</span><br/>Iki pasimatymo!",
        bonusText:
          "Nuoširdžiai dėkojame, kad pasinaudojote <b>mūsų specialiu pasiūlymu</b>! Esate mūsų vertingiausias klientas, ir jūsų pasirinkimas skatina mus tobulėti. Turite galimybę gauti <b>mūsų lojalumo programos</b> privalumus.",
        phoneLabel:
          "Palikite savo telefono numerį, kad galėtumėte pasinaudoti šiuo pasiūlymu",
        surveyQuestion: "Ar jums patiko mūsų parduotuvė?",
        surveyYes: "Man labai patiko",
        surveyNo: "Galėtų būti geriau",
        homeButton: "Pradžia",
        close: "Atšaukti",
        confirm: "Patvirtinti",
      },
    },
    landing: {
      title: "Informacija apie produktą",
      text: "Mūsų gaminiai yra geriausi iš visų kitų gaminių",
    },
    welcomePage: {
      ok: "OK",
      cancel: "Atšaukti",
    },
    infoPage: {
      message: "Atsiprašome.<br />Iki pasimatymo!",
      notFound: "Atsiprašome.<br />Puslapis nerastas",
      googleAuthError: "Bandant prisijungti per Google įvyko klaida",
      alreadyRegisteredGoa: "Vartotojas su tokiu el. paštu jau užregistruotas.",
      userNotFound:
        "Negalėjome jūsų prisijungti per „Google“, nes nėra paskyros, susietos su šiuo el. paštu.<br /><br /> Prašome užsiregistruoti naują paskyrą arba išbandyti kitą prisijungimo metodą.",
      ok: "OK",
      toLogin: "Prisijungti",
      toSignup: "Registruotis",
    },
    footer: {
      help: "Pagalba",
    },
    errorPage: {
      [EC_FAILED_FETCH_CONFIG_DATA]: "Nepavyko gauti konfigūracijos",
      [EC_INVALID_ISSUER_SYS_NAME]:
        "Kažkas negerai su nuoroda, prašome nuskaityti dar kartą",
      [EC_NO_CARD]: "Lojalumo kortelė nerasta",
      unknownTitle: "Oops!",
      unknownText:
        "Lojalumo programa, kurią bandote pasiekti, dar neprasidėjo arba URL yra neteisingas. Sekite naujienas!",
      actionText: "Eiti į pagrindinį puslapį",
    },
  },
};

export default lt;
