import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Typography } from "@mui/material";
import {
  getRouterService,
  getSignupService,
} from "../../../diContainer/getDependencies";
import LayoutHeadless from "../../components/layouts/LayoutHeadless";
import Loader from "../../components/layouts/Loader";

const SignUpVendorIntegrationPage: FC = () => {
  const { t } = useTranslation("translation");

  const signupService = getSignupService();
  const routerService = getRouterService();

  const [integrationError, setError] = useState<string | null>(null);

  const checkVendorIntegration = async () => {
    const error = await signupService.vendorIntegration();

    if (error?.isError) {
      setError(error.message);
    }
  };

  useEffect(() => {
    checkVendorIntegration();
  }, [checkVendorIntegration]);

  const handleClose = () => {
    routerService.navigateToIssuerPath("/signup");
  };

  if (integrationError) {
    return (
      <LayoutHeadless>
        <Typography
          variant="h6"
          textAlign="center"
          sx={{ marginBottom: 4, textWrap: "initial" }}
        >
          {t(`signUp.vendorIntegration.${integrationError}`)}
        </Typography>
        <Button variant="contained" fullWidth onClick={handleClose}>
          {t("signUp.vendorIntegration.close")}
        </Button>
      </LayoutHeadless>
    );
  }

  return <Loader />;
};

export default SignUpVendorIntegrationPage;
