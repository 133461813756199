import { FC, ReactElement } from "react";
import { AppBar, Box, Container, Toolbar, Typography } from "@mui/material";
// import logo from "../../images/delikat-logo.png";
// import logo from "../../images/delikat-logoV2.svg";
import logo from "../../images/delikat-logoV3.png";
import { getIssuerConfigService } from "../../../diContainer/getDependencies";
import { useObservable } from "@roketus/web-toolkit";
// import Footer from "./Footer";

interface IProps {
  children: ReactElement;
}

const LayoutDelicate: FC<IProps> = ({ children }) => {
  const issuerConfigService = getIssuerConfigService();

  const issuerConfigData = useObservable(issuerConfigService.data$);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <AppBar sx={{ p: 0 }}>
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Box>
            <img src={logo} alt="Delikat Logo" width={44} />
          </Box>
          <Typography variant="h6" component="h5">
            {issuerConfigData?.config.companyName ??
              issuerConfigData?.config?.issuer}
          </Typography>
        </Toolbar>
      </AppBar>

      <Container component="main" sx={{ px: 3, flex: "1 1", maxWidth: 500 }}>
        {children}
      </Container>

      {/* <Footer /> */}
    </Box>
  );
};

export default LayoutDelicate;
