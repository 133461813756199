import { FC } from "react";
import { useParams } from "react-router-dom";
import { useObservable } from "@roketus/web-toolkit";
import { useTranslation } from "react-i18next";
import type { IOTPFormData } from "../../../boundary/forms/IOTPFormData";
import type { TSetFormFieldError } from "../../../boundary/forms/TSetFormFieldError";
import { buildAnalyticMessageEntity } from "../../../domain/entities/analyticEntity";
import { isDelikatIssuer } from "../../../domain/specs/issuerToken";
import {
  getMessageBus,
  getSignupService,
} from "../../../diContainer/getDependencies";
import LayoutHeadless from "../../components/layouts/LayoutHeadless";
import { ValidateOtp } from "../../components/ValidateOtp";

const SignUpOTPPage: FC = () => {
  const { t } = useTranslation("translation");

  const { issuerSysName } = useParams();

  const signupService = getSignupService();
  const messageBus = getMessageBus();

  const data = useObservable(signupService.data$);
  const phoneNumber = data?.phoneNumber ?? "";

  const sendCode = (
    otp: string,
    setError: TSetFormFieldError<IOTPFormData>
  ) => {
    const analyticEvent = buildAnalyticMessageEntity({
      action: "SendOtpButtonClick",
      source: "SignUpOTPPage",
    });
    messageBus.send(analyticEvent);

    signupService.setCode(otp, setError);
  };

  const handleResend = () => {
    const analyticEvent = buildAnalyticMessageEntity({
      action: "ResendOtpButtonClick",
      source: "SignUpOTPPage",
    });
    messageBus.send(analyticEvent);

    signupService.resendCode();
  };

  const handleSkipStep = () => {
    const analyticEvent = buildAnalyticMessageEntity({
      action: "SkipOtpButtonClick",
      source: "SignUpOtpPage",
    });
    messageBus.send(analyticEvent);

    signupService.skipPhoneActivationStep();
  };

  return (
    <LayoutHeadless title={t("header.validateOtp") ?? ""}>
      <ValidateOtp
        onSendOtpClick={sendCode}
        phoneNumber={phoneNumber}
        onResendActivationCode={handleResend}
        isLoading={data?.loading ?? false}
        isSkipable={!isDelikatIssuer(issuerSysName?.toLowerCase())}
        onSkipStep={handleSkipStep}
      />
    </LayoutHeadless>
  );
};

export default SignUpOTPPage;
