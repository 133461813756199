import { FC, useCallback, useEffect, useState } from "react";
import { useObservable } from "@roketus/web-toolkit";
import { CardMedia, Skeleton, useTheme, useMediaQuery } from "@mui/material";
import { getImageService } from "../../../../diContainer/getDependencies";

interface IProps {
  imgId?: string | null;
}

const CardThumbnail: FC<IProps> = ({ imgId }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const imageService = getImageService();

  const { loading } = useObservable(imageService.data$) ?? {};

  const [imgUrl, setImgUrl] = useState<string | null>(null);

  const getImgUrl = useCallback(async (id: string) => {
    const url = await imageService.getImageUrlById(id);
    setImgUrl(url);
  }, [imageService]);

  useEffect(() => {
    if (imgId) {
      getImgUrl(imgId);
    }
  }, [imgId, getImgUrl]);

  const height = isMobile ? 157 : 167;

  if (loading) {
    return <Skeleton width={height} height={height} sx={{ ml: "auto" }} />;
  }

  return (
    <CardMedia
      component="img"
      image={imgUrl ?? ""}
      alt="Thumbnail"
      sx={{
        width: "auto",
        height: height,
        objectFit: "cover",
        ml: "auto",
      }}
    />
  );
};

export default CardThumbnail;
