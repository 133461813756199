import { Box, Container, Typography } from "@mui/material";
import React, { FC } from "react";
import Footer from "./Footer";
import logoLoyalty from "../../icons/logo-loyalty.svg";

interface IProps {
  title?: string | React.ReactElement;
  children?: React.ReactNode;
}

const LayoutHeadless: FC<IProps> = ({ title, children }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <Container
        component="main"
        maxWidth="xs"
        sx={{ padding: { xs: "0 2rem" }, marginTop: "2rem" }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box mb={title ? 3 : 7}>
            <img src={logoLoyalty} alt="Atomic Loyalty Logo" width={72} />
          </Box>
          {title && (
            <Typography
              component="h1"
              variant="h5"
              fontSize={20}
              fontWeight="normal"
              align="center"
              mb={3}
            >
              {title}
            </Typography>
          )}

          {children}
        </Box>
      </Container>
      <Footer showLangSelector />
    </Box>
  );
};

export default LayoutHeadless;
