import { FC } from "react";
import { Trans } from "react-i18next";
import { getRouterService } from "../../../../diContainer/getDependencies";
import styles from "./styles.module.scss";
import Arrow from "../../../icons/Arrow";

const DemoatomicHomeButton: FC = () => {
  const routerService = getRouterService();

  const handleClose = () => {
    routerService.navigateToIssuerPath("/");
  };

  return (
    <button className={styles.buttonHome} onClick={handleClose}>
      <Trans i18nKey={`action.demoatomic.homeButton`}></Trans>
      <Arrow />
    </button>
  );
};

export default DemoatomicHomeButton;
