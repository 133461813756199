import { FieldValues, Path, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import type { TSetFormFieldError } from "../../../boundary/forms/TSetFormFieldError";
import EmailInput from "../form/EmailInput";
import LoyaltyPolicy from "../form/LoyaltyPolicy";

interface IProps<T extends FieldValues> {
  loading?: boolean;
  hasPolicy?: boolean;
  submitLabel: string;
  onSubmit: (email: string, setError: TSetFormFieldError<T>) => void;
}

const EmailForm = <T extends FieldValues>({
  loading,
  hasPolicy,
  submitLabel,
  onSubmit,
}: IProps<T>) => {
  const { t: tError } = useTranslation("translation", {
    keyPrefix: "common.errors.email",
  });

  const { handleSubmit, control, setError, formState } = useForm<T>();

  const setFormError = (field: Path<T> | "root", messageKey: string) => {
    const message = tError(messageKey);
    setError(field as "root" | Path<T>, { type: "manual", message });
  }

  const submitForm = (form: T) => {
    onSubmit(form.email, setFormError);
  };

  return (
    <Box
      component="form"
      noValidate
      onSubmit={handleSubmit(submitForm)}
      sx={{ display: "block", width: "100%", mb: 2 }}
    >
      <EmailInput<T> control={control} />

      {hasPolicy && <LoyaltyPolicy control={control} />}

      <LoadingButton
        type="submit"
        variant="contained"
        onClick={handleSubmit(submitForm)}
        fullWidth
        loading={loading}
        data-testid="submit"
        disabled={!formState.isValid}
        sx={{ mt: 2 }}
      >
        {submitLabel}
      </LoadingButton>
    </Box>
  );
};

export default EmailForm;
