import { get, isEmpty, isString } from "lodash";
import { StateSubject, persistentStorage } from "@roketus/web-toolkit";
import type {
  IActionService,
  IActionServiceData,
} from "../../boundary/IActionService";
import type { ISurveyFormPayload } from "../../boundary/forms/IFreeSurveyFormData";
import {
  getAuthService,
  getIssuerConfigService,
  getRouterService,
  getSdk,
  getSignupService,
} from "../../diContainer/getDependencies";
import { ActionsPaths, AnonymousActionsPaths } from "../router/actionsRoutes";
import { getErrorCodeFromResponse } from "../../utils/response-errors";
import { pushErrorToFaro, pushLogToFaro } from "../../utils/faroLogs";
import { downloadIOSCardAsBlob, getUniqueAnonymousInfo } from "../helpers";
import { toPostSurveyPayloadDTO } from "../data/survey/toPostSurveyPayloadDTO";

// Response types from server for dispatch action endroint (depending on action type)
export const ACTIONS_WITH_DOWNLOAD_RESPONSE_TYPE = [
  ActionsPaths.Coupon, // ios - blob, other - google link in json
];
export const ACTIONS_WITH_EMPTY_RESPONSE_TYPE = [
  ActionsPaths.Promo,
  ActionsPaths.VodohraiPromo,
  ActionsPaths.SensePromo,
]; // 204 - nobody
export const ACTIONS_WITH_JSON_RESPONSE_TYPE = [
  ActionsPaths.Bonus,
  ActionsPaths.Notification,
]; // json

const ACTION_STORAGE_KEY = "action";

const stateMachine = new StateSubject<IActionServiceData>({
  dispatchKey: "",
  actionType: "",
  loading: false,
  resultData: null,
});

const storeAction: IActionService["storeAction"] = ({
  actionType,
  dispatchKey,
}: {
  actionType: string;
  dispatchKey: string;
}) => {
  stateMachine.setState({
    actionType,
    dispatchKey,
  });
};

const clearAction: IActionService["clearAction"] = () => {
  stateMachine.setState({
    actionType: "",
    dispatchKey: "",
  });
};

const setActionToStorage: IActionService["setActionToStorage"] = () => {
  const { actionType, dispatchKey } = stateMachine.state$.value || {};

  if (actionType) {
    persistentStorage.setItem(ACTION_STORAGE_KEY, { actionType, dispatchKey });
  }
};

const restoreActionFromStorage: IActionService["restoreActionFromStorage"] =
  () => {
    const action: { actionType?: string; dispatchKey?: string } =
      persistentStorage.getItem(ACTION_STORAGE_KEY);

    if (action) {
      stateMachine.setState({
        actionType: action.actionType,
        dispatchKey: action.dispatchKey,
      });

      return action;
    }

    return null;
  };

const removeActionFromStorage: IActionService["removeActionFromStorage"] =
  () => {
    persistentStorage.removeItem(ACTION_STORAGE_KEY);
  };

const approveAction: IActionService["approveAction"] = async (
  phoneOrEmail,
  params,
  setError
) => {
  stateMachine.setState({
    loading: true,
  });

  const routerService = getRouterService();
  const signUpService = getSignupService();
  const authService = getAuthService();

  const { actionType } = stateMachine.state$.value;
  const { isAuthenticated } = authService.data$.value;

  if (isAuthenticated) {
    stateMachine.setState({
      loading: false,
    });

    actionType === ActionsPaths.Survey
      ? routerService.navigateToIssuerPath("/survey")
      : routerService.navigateToIssuerPath("/action", { actionType });

    return;
  }

  await signUpService.signupByCurrentMethod(phoneOrEmail, params, setError);

  stateMachine.setState({
    loading: false,
  });
};

const approvePublicAction: IActionService["approvePublicAction"] = async (
  params
) => {
  const routerService = getRouterService();
  const { actionType } = stateMachine.state$.value;

  if (actionType === AnonymousActionsPaths.FreeSurvey) {
    routerService.navigateToIssuerPath(`/free-survey?${params}`);
  } else {
    await dispatchPublicAction();
  }
};

const dispatchAction = async () => {
  stateMachine.setState({
    loading: true,
  });

  const sdk = getSdk();
  const routerService = getRouterService();

  const { dispatchKey, actionType } = stateMachine.state$.value;

  try {
    const data = await sdk.action.dispatchAction(dispatchKey).result;

    if (
      ![
        ActionsPaths.Bonus,
        ActionsPaths.Notification,
        ...ACTIONS_WITH_EMPTY_RESPONSE_TYPE,
      ].includes(actionType as ActionsPaths)
    ) {
      const googlePassUrl = get(data, "data[0]attributes.google_pass_url");
      googlePassUrl && window.open(googlePassUrl, "_blank");
    }

    pushLogToFaro("Success: dispatch action", { actionType });

    routerService.navigateToIssuerPath("/action/success", { actionType });

    clearAction();

    stateMachine.setState({
      loading: false,
    });
  } catch (e) {
    pushErrorToFaro(e, {
      actionType,
      message: "Error: failed to dispatch action",
    });

    const errorCode = getErrorCodeFromResponse(e);

    routerService.navigateToIssuerPath("/action/info", {
      actionType,
      errorCode,
    });

    clearAction();

    stateMachine.setState({
      loading: false,
    });
  }
};

const dispatchPublicAction = async () => {
  stateMachine.setState({
    loading: true,
  });

  const routerService = getRouterService();
  const issuerConfigService = getIssuerConfigService();

  const { actionType, dispatchKey } = stateMachine.state$.value;
  const issuerSysName = issuerConfigService.getIssuer();

  try {
    const baseURL = process.env.REACT_APP_LOYALTY_API_URL;
    const url = `${baseURL}smart-link/dispatch-public/${dispatchKey}`;
    const params = `?errorRedirectUrl=${window.location.origin}/${issuerSysName}/action/info`;
    const linkTarget = "_blank";

    pushLogToFaro("Dispatch public action", {
      actionType,
    });

    // problems with CORS from google
    // const data = await sdk.action.dispatchPublicAction(dispatchKey).result;

    // [BE] if success - redirects to download page, else - to error page
    window.open(`${url}${params}`, linkTarget);

    routerService.navigateToIssuerPath("/action/success", { actionType });

    clearAction();

    stateMachine.setState({
      loading: false,
    });
  } catch (e) {
    pushErrorToFaro(e, {
      actionType,
      message: "Error: failed to dispatch public action",
    });

    routerService.navigateToIssuerPath("/action/info", { actionType });

    clearAction();

    stateMachine.setState({
      loading: false,
    });
  }
};

const submitSurveyForm = async (surveyFormPayload: ISurveyFormPayload) => {
  stateMachine.setState({
    loading: true,
  });

  const sdk = getSdk();
  const routerService = getRouterService();

  const issuerSysName = sdk.getIssuer() as string;
  const jwt = sdk.getToken();

  if (!isString(jwt) || isEmpty(jwt)) {
    routerService.navigateToIssuerPath("/signin");
    return;
  }

  //TODO: if is not authorized - no access to the page and method
  try {
    const payload = toPostSurveyPayloadDTO(
      issuerSysName,
      {
        authorized: true,
        jwt,
      },
      surveyFormPayload
    );

    await sdk.survey.postSurvey(payload).result;

    routerService.navigateToIssuerPath("/action");

    stateMachine.setState({
      loading: false,
    });
  } catch (error) {
    routerService.navigateToIssuerPath("/info");

    stateMachine.setState({
      loading: false,
    });
  }
};

const submitFreeSurveyForm = async (
  surveyPayload: ISurveyFormPayload,
  issuerSysName?: string
) => {
  if (!issuerSysName) {
    throw new Error("Not Found");
  }

  stateMachine.setState({
    loading: true,
  });

  const sdk = getSdk();
  const routerService = getRouterService();

  try {
    const payload = toPostSurveyPayloadDTO(
      issuerSysName,
      {
        authorized: false,
        jwt: getUniqueAnonymousInfo(),
      },
      surveyPayload
    );

    await sdk.survey.postSurvey(payload).result;

    routerService.navigateToIssuerPath("/success");

    stateMachine.setState({
      loading: false,
    });
  } catch (error) {
    routerService.navigateToIssuerPath("/info");

    stateMachine.setState({
      loading: false,
    });
  }
};

const dispatchIOSCardAction = async () => {
  stateMachine.setState({
    loading: true,
  });

  const sdk = getSdk();
  const routerService = getRouterService();

  const { actionType, dispatchKey } = stateMachine.state$.value;

  try {
    const IOSCard = (await sdk.action.dispatchIOSCardAction(dispatchKey)
      .result) as unknown as Blob;

    downloadIOSCardAsBlob(IOSCard);

    pushLogToFaro("Success: dispatch action on iOS", {
      actionType,
    });

    routerService.navigateToIssuerPath("/action/success", { actionType });

    clearAction();

    stateMachine.setState({
      loading: false,
    });
  } catch (e) {
    pushErrorToFaro(e, {
      message: "Error: failed to dispatch action on iOS",
      actionType,
    });

    routerService.navigateToIssuerPath("/action/info", { actionType });

    clearAction();

    stateMachine.setState({
      loading: false,
    });
  }
};

export const actionService: IActionService = {
  data$: stateMachine.state$,
  storeAction,
  clearAction,
  setActionToStorage,
  restoreActionFromStorage,
  removeActionFromStorage,
  approveAction,
  approvePublicAction,
  dispatchAction,
  dispatchIOSCardAction,
  dispatchPublicAction,
  submitSurveyForm,
  submitFreeSurveyForm,
};
