import { FC } from "react";
// import { Settings as SliderSettings } from "react-slick";
import { useObservable } from "@roketus/web-toolkit";
import { useTheme, useMediaQuery, Skeleton } from "@mui/material";
import { getProfileService } from "../../../../diContainer/getDependencies";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MainCardPreview from "./MainCardPreview";
import {
  CarouselContainer,
  // StyledSlider,
  StyledSliderArrow,
} from "./styledComponents";
import { InnerContainer } from "../../../../styles/InnerContainer";
import DownloadCardBox from "../DownloadCardBox/DownloadCardBox";

interface IArrowProps {
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

const NextArrow: FC<IArrowProps> = ({ className, style, onClick }) => (
  <StyledSliderArrow
    className={className}
    left="100%"
    onClick={onClick}
    aria-label="next slide"
  >
    <ArrowForwardIosIcon style={style} />
  </StyledSliderArrow>
);

const PrevArrow: FC<IArrowProps> = ({ className, style, onClick }) => (
  <StyledSliderArrow
    className={className}
    left="0"
    onClick={onClick}
    aria-label="previous slide"
  >
    <ArrowBackIosNewIcon style={style} />
  </StyledSliderArrow>
);

const CardList: FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const profileService = getProfileService();

  const profileData = useObservable(profileService.data$);

  // const sliderSettings: SliderSettings = {
  //   dots: cards.length > 1,
  //   arrows: !isMobile && cards.length > 1,
  //   // infinite: cards.length > 1,
  //   infinite: false,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   // adaptiveHeight: true,
  //   nextArrow: <NextArrow />,
  //   prevArrow: <PrevArrow />,
  // };

  if (!profileData || !profileData?.isLoaded || profileData?.loading) {
    return (
      <InnerContainer>
        <Skeleton
          animation="wave"
          variant="rounded"
          width={isMobile ? "100%" : 450}
          height={isMobile ? 517 : 544}
          sx={{ margin: "0 auto" }}
        />
        <Skeleton
          animation="wave"
          variant="rounded"
          width={isMobile ? "100%" : 450}
          height={isMobile ? 287 : 69}
          sx={{ margin: "2rem auto" }}
        />
      </InnerContainer>
    );
  }

  return (
    <CarouselContainer>
      <InnerContainer>
        <MainCardPreview cardInfo={profileData.cardInfo} />
      </InnerContainer>
      <DownloadCardBox />
      {/* <StyledSlider {...sliderSettings}>
        {cards.map((card, idx) => ( */}
      {/* <MainCardPreview key={idx} cardInfo={card} /> */}
      {/* ))}
      </StyledSlider> */}
    </CarouselContainer>
  );
};

export default CardList;
