import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { Control, Controller, FieldValues, Path, PathValue, RegisterOptions } from "react-hook-form";
import { MenuItem, TextField, TextFieldProps } from "@mui/material";

interface IProps<R extends FieldValues> {
  name: Path<R>;
  defaultValue?: PathValue<R, Path<R>>;
  control: Control<R>;
  options?: { label: string; value: string }[];
  rules?: Omit<
    RegisterOptions<R, any>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
}

const Select = <R extends FieldValues>({
  name,
  control,
  rules,
  options = [],
  defaultValue,
  ...props
}: IProps<R> & TextFieldProps) => {
  const { t } = useTranslation("translation", { keyPrefix: "forms" });

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => {
        const errorProps = fieldState.error
          ? {
              error: true,
              helperText: isEmpty(fieldState.error.message)
                ? t(`default.${fieldState.error.type}`)
                : fieldState.error.message,
            }
          : {};

        return (
          <TextField
            {...props}
            {...field}
            select
            fullWidth
            required={!!rules?.required}
            defaultValue={defaultValue}
            {...errorProps}
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        );
      }}
    />
  );
};

export default Select;
