import { isNull } from "lodash";
import bwipjs from "bwip-js";
import { IBarcodeType } from "../../../../boundary/IProfileService";

export const createRenderBarcode =
  (barcode: { text: string; type: IBarcodeType; alternateText?: string }) =>
  (el: HTMLCanvasElement | null) => {
    const { text, type } = barcode;
    if (isNull(el)) return;

    let width = 300;
    let height = 300;
    if (type === "pdf417" || type === "code128") {
      width = 190;
      height = 52;
    }

    bwipjs.toCanvas(el, {
      bcid: type,
      text,
      width,
      height,
    });
  };
