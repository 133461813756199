import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { useObservable } from "@roketus/web-toolkit";
import { Typography } from "@mui/material";
import {
  getAuthService,
  getIssuerConfigService,
  getMessageBus,
  getSigninService,
} from "../../../diContainer/getDependencies";
import { buildAnalyticMessageEntity } from "../../../domain/entities/analyticEntity";
import { AuthMethod } from "../../../domain/specs/authMethods";
import type { IPhoneFormData } from "../../../boundary/IPhoneFormData";
import type { IEmailFormData } from "../../../boundary/IEmailFormData";
import type { TSetFormFieldError } from "../../../boundary/forms/TSetFormFieldError";
import LayoutHeadless from "../../components/layouts/LayoutHeadless";
import { StyledLink } from "../../../styles/StyledLink";
import AuthForm from "../../components/authForms/AuthForm";

const SignInPage: FC = () => {
  const { t } = useTranslation("translation", { keyPrefix: "signIn.index" });

  const navigate = useNavigate();
  const { issuerSysName } = useParams();

  const issuerConfigService = getIssuerConfigService();
  const signInService = getSigninService();
  const authService = getAuthService();
  const messageBus = getMessageBus();

  const issuerConfigData = useObservable(issuerConfigService.data$);
  const authData = useObservable(signInService.data$);

  const loginByPhone = async (
    phone: string,
    setError: TSetFormFieldError<IPhoneFormData>
  ) => {
    const analyticEvent = buildAnalyticMessageEntity({
      action: "SignInByPhoneButtonClick",
      source: "SignInPage",
    });
    messageBus.send(analyticEvent);

    await signInService.logInByPhone(phone, setError);
  };

  const loginByEmail = async (
    email: string,
    setError: TSetFormFieldError<IEmailFormData>
  ) => {
    const analyticEvent = buildAnalyticMessageEntity({
      action: "SignInByEmailButtonClick",
      source: "SignInPage",
    });
    messageBus.send(analyticEvent);

    await signInService.logInByEmail(email, setError);
  };

  const signinByGoogle = async () => {
    const analyticEvent = buildAnalyticMessageEntity({
      action: "SignInByGoaButtonClick",
      source: "SignInPage",
    });
    messageBus.send(analyticEvent);

    await signInService.logInByGoogle();
  };

  const changeSigninMethod = (method: AuthMethod) => {
    switch (method) {
      case AuthMethod.Google:
        signinByGoogle();
        break;
      case AuthMethod.Phone:
      case AuthMethod.Email:
      default:
        signInService.setSigninMethod(method);
    }
  };

  const goToSignUp = (e: React.MouseEvent) => {
    authService.removeTokens();
    navigate(`/${issuerSysName}/signup`);
  };

  return (
    <LayoutHeadless
      title={
        issuerConfigData?.config?.companyName ? (
          <Trans
            t={t}
            i18nKey="header"
            values={{ company: issuerConfigData.config.companyName }}
          />
        ) : (
          ""
        )
      }
    >
      <Typography variant="body1" pb={2}>
        <Trans t={t} i18nKey="text" components={{ bold: <strong /> }} />
      </Typography>

      <AuthForm
        t={t}
        loading={authData?.loading}
        method={authData?.signinMethod}
        setAuthMethod={changeSigninMethod}
        authByPhone={loginByPhone}
        authByEmail={loginByEmail}
      />

      <Typography>
        {t("signUpLabel")}{" "}
        <StyledLink
          component="button"
          data-testid="signup"
          onClick={goToSignUp}
        >
          {t("signUp")}
        </StyledLink>
      </Typography>
    </LayoutHeadless>
  );
};

export default SignInPage;
