import { type FC, useMemo } from "react";
import { useObservable } from "@roketus/web-toolkit";
import { Box, Button, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import {
  getProfileService,
  getRouterService,
  getUpdateProfileService,
} from "../../../diContainer/getDependencies";
import {
  createAddressString,
  getDateFormatedByLocale,
} from "../../../utils/utils";
import ReadonlyField from "./ReadonlyField";

const ProfileSettings: FC = () => {
  const { t, i18n } = useTranslation("translation", {
    keyPrefix: "profile.form",
  });

  const profileService = getProfileService();
  const updateProfileService = getUpdateProfileService();
  const routerService = getRouterService();

  const profileData = useObservable(profileService.data$);

  const profile = useMemo(
    () => profileData?.profile?.profile?.[0],
    [profileData]
  );

  const handleEditProfileInfo = () => {
    routerService.navigateToIssuerPath("/settings/profile/info");
  };

  const handleEditBirth = () => {
    routerService.navigateToIssuerPath("/settings/profile/birth");
  };

  const handleEditGender = () => {
    routerService.navigateToIssuerPath("/settings/profile/gender");
  };

  const handleEditWorkplace = () => {
    routerService.navigateToIssuerPath("/settings/profile/workplace");
  };

  const handleEditAddress = () => {
    routerService.navigateToIssuerPath("/settings/profile/address");
  };

  const handleEditPhone = () => {
    routerService.navigateToIssuerPath("/settings/phone");
  };

  const handleEditEmail = () => {
    routerService.navigateToIssuerPath("/settings/email");
  };

  const handleVerifyPhone = () => {
    updateProfileService.verifyProfile();
  };

  return (
    <>
      <ReadonlyField
        label="fullName"
        value={`${profile?.firstName} ${profile?.lastName}`}
        onEdit={handleEditProfileInfo}
      />

      <Box>
        <ReadonlyField
          label="phone"
          value={`${profileData?.profile?.phone}`}
          shouldVerify
          isVerified={profileData?.profile?.phoneVerified}
          onEdit={handleEditPhone}
        />
        {!profileData?.profile?.phoneVerified && (
          <Typography variant="body2" fontSize={{ xs: 16 }} color="warning">
            <Trans
              i18nKey="profile.form.verifyPhone"
              components={{
                button: (
                  <Button
                    sx={{ fontWeight: 700 }}
                    onClick={handleVerifyPhone}
                  ></Button>
                ),
              }}
            />
          </Typography>
        )}
      </Box>

      {/* TODO add change email flow after email implementation */}
      {false && (
        <ReadonlyField
          label="email"
          value={`${profileData?.profile?.email}`}
          shouldVerify
          isVerified={profileData?.profile?.emailVerified}
          onEdit={handleEditEmail}
        />
      )}

      <ReadonlyField
        label="birthDateLabel"
        value={getDateFormatedByLocale(i18n.language, profile?.birth)}
        onEdit={handleEditBirth}
      />

      <ReadonlyField
        label="genderLabel"
        value={t(`gender.${profile?.gender}`) ?? ""}
        onEdit={handleEditGender}
      />

      <ReadonlyField
        label="workplaceLabel"
        value={profile?.workplace}
        onEdit={handleEditWorkplace}
      />

      <ReadonlyField
        label="addressLabel"
        value={createAddressString(profile?.address)}
        onEdit={handleEditAddress}
      />
    </>
  );
};

export default ProfileSettings;
