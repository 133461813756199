import { Trans, useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import SuccessDispatchIcon from "@mui/icons-material/Verified";
import { getSuccessContentByActionType } from "../../../utils/action-results";
import {
  getIssuerConfigService,
  getRouterService,
} from "../../../diContainer/getDependencies";
import IssuerToken, { isDelikatIssuer } from "../../../domain/specs/issuerToken";
import LayoutHeadless from "../../components/layouts/LayoutHeadless";
import DemoatomicActionSuccess from "../../components/actionComponents/demoatomic/DemoatomicActionSuccess";
import { RegisterCompanySuccess } from "../../components/actionComponents/delicate/RegisterCompanySuccess";

const ActionSuccessPage = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "action.dispatchResult",
  });
  const issuerConfigService = getIssuerConfigService();
  const routerService = getRouterService();

  const location = useLocation();
  const { actionType } = location.state || {};
  const issuerSysName = issuerConfigService.getIssuer();

  const handleClose = () => {
    // actionService.clearAction();
    routerService.navigateToIssuerPath("/");
  };

  if (issuerSysName === IssuerToken.Demoatomic) {
    return <DemoatomicActionSuccess />;
  }

  if (isDelikatIssuer(issuerSysName)) {
    return <RegisterCompanySuccess />;
  }

  const { titleKey, textKey, buttonTextKey } =
    getSuccessContentByActionType(actionType);

  return (
    <LayoutHeadless>
      <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
        <SuccessDispatchIcon sx={{ fontSize: "50px" }} />
        <Typography variant="h6" gutterBottom align="center">
          {t(titleKey)}
        </Typography>
        {textKey && (
          <Typography gutterBottom align="center">
            <Trans i18nKey={textKey} t={t} components={{ br: <br /> }} />
          </Typography>
        )}
        <Button variant="contained" onClick={handleClose} sx={{ mt: "10px" }}>
          {t(buttonTextKey)}
        </Button>
      </Box>
    </LayoutHeadless>
  );
};

export default ActionSuccessPage;
