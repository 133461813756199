import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Typography } from "@mui/material";
import { getSignupService } from "../../../diContainer/getDependencies";
import LayoutHeadless from "../../components/layouts/LayoutHeadless";
import googleIcon from "../../images/google-logo.png";

const SignUpGoogleSuccessPage: FC = () => {
  const { t } = useTranslation("translation");

  const signupService = getSignupService();

  const handleNext = () => {
    signupService.goaActivation();
  };

  return (
    <LayoutHeadless title={t("header.signup") ?? ""}>
      <Typography
        variant="h6"
        textAlign="center"
        sx={{ marginBottom: 3, textWrap: "initial" }}
      >
        {t("signUp.goa.successText")}
      </Typography>
      <Box mb={4}>
        <img src={googleIcon} alt="Google logo" width={57} />
      </Box>
      <Button variant="contained" fullWidth onClick={handleNext}>
        {t("signUp.goa.submit")}
      </Button>
    </LayoutHeadless>
  );
};

export default SignUpGoogleSuccessPage;
