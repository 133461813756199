import { Trans } from "react-i18next";
import LayoutHeadless from "./layouts/LayoutHeadless";
import { Typography } from "@mui/material";

export const NotFound: React.FC = () => {
  return (
    <LayoutHeadless>
      <Typography align="center">
        <Trans i18nKey={"infoPage.notFound"} components={{ br: <br /> }} />
      </Typography>
    </LayoutHeadless>
  );
};
