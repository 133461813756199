import { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import IssuerToken from "../../domain/specs/issuerToken";
import { getAuthService, getRouterService } from "../../diContainer/getDependencies";
import { getErrorFromQuery } from "../../utils/url-params";
import LayoutHeadless from "../components/layouts/LayoutHeadless";
import { NegativeRetailateResult } from "../components/retailate/NegativeRetailateResult";
import { DemoatomicActionCancel } from "../components/actionComponents/demoatomic/DemoatomicActionCancel";

const InfoPage: React.FC = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "infoPage",
  });

  const authSevice = getAuthService();
  const routerService = getRouterService();

  const { issuerSysName } = useParams();
  const [searchParams] = useSearchParams();
  const errorBase64 = searchParams.get("err");

  const textKey = useMemo(() => getErrorFromQuery(errorBase64), [errorBase64]);

  const handleSignin = () => {
    authSevice.removeTokens();
    routerService.navigateToPathWithActionIfExist("/signin");
  };

  const handleSignup = () => {
    authSevice.removeTokens();
    routerService.navigateToPathWithActionIfExist("/signup");
  };

  if (textKey) {
    return (
      <LayoutHeadless>
        <Typography mb={4} align="center">
          <Trans t={t} i18nKey={textKey} components={{ br: <br /> }} />
        </Typography>
        <Box display="flex" gap={2} width="100%">
          <Button fullWidth variant="contained" onClick={handleSignin}>{t("toLogin")}</Button>
          <Button fullWidth variant="contained" onClick={handleSignup}>{t("toSignup")}</Button>
        </Box>
      </LayoutHeadless>
    );
  }

  if (issuerSysName?.toLowerCase() === IssuerToken.Retailete) {
    return <NegativeRetailateResult />;
  }

  if (issuerSysName?.toLowerCase() === IssuerToken.Demoatomic) {
    return <DemoatomicActionCancel />;
  }

  return (
    <LayoutHeadless>
      <Typography gutterBottom align="center">
        <Trans t={t} i18nKey={"message"} components={{ br: <br /> }} />
      </Typography>
    </LayoutHeadless>
  );
};

export default InfoPage;
