import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Alert, Button } from "@mui/material";
import { getUpdateProfileService } from "../../../diContainer/getDependencies";

const ActivationWarning: FC = () => {
  const { t } = useTranslation("translation", { keyPrefix: "profile" });

  const updateProfileService = getUpdateProfileService();

  const handleActivation = () => {
    updateProfileService.verifyProfile();
  };

  return (
    <Alert
      severity="warning"
      sx={(theme) => ({
        alignItems: "center",
        "&.MuiAlert-root": {
          position: "fixed",
          top: theme.custom.headerHeight,
          width: "100%",
          "@media screen and (min-width: 600px)": {
            position: "sticky",
            marginTop: `-${theme.custom.mainPadding}`,
          },
        },
        "&> .MuiAlert-icon": { flex: "0 0 22px" },
      })}
      action={
        <Button color="inherit" size="small" onClick={handleActivation}>
          {t("activate")}
        </Button>
      }
    >
      {t("notActivatedWarning")}
    </Alert>
  );
};

export default ActivationWarning;
