import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { init } from "./diContainer/initializeDependencies";
import { getRouter } from "./diContainer/getDependencies";
import { initAnalyticsService } from "./adapters/services/analyticsService";
import { initI18n } from "./adapters/i18n/i18n";
import { initFaro } from "./adapters/faro/initFaro";
import Loader from "./view/components/layouts/Loader";
import "../src/styles/index.scss";

init();
initAnalyticsService();
initI18n();
export const faro = initFaro();

const router = getRouter();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} fallbackElement={<Loader />} />
  </React.StrictMode>
);
