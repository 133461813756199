import ProfileLayout from "../../components/layouts/ProfileLayout";
import CardList from "../../components/Profile/CardsBox/CardList";

const ProfilePage = () => {
  return (
    <ProfileLayout>
      <CardList />
    </ProfileLayout>
  );
};

export default ProfilePage;
