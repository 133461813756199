import { FC } from "react";
import { useTranslation } from "react-i18next";
import LayoutHeadless from "../../components/layouts/LayoutHeadless";
import SuccessBox from "../../components/SuccessBox";

const SignUpEmailConfirmedPage: FC = () => {
  const { t } = useTranslation("translation");

  // const handleNext = () => {};

  return (
    <LayoutHeadless title={t("header.signup") ?? ""}>
      <SuccessBox message={t("signUp.emailConfirmed")} closeUrl="/" />
    </LayoutHeadless>
  );
};

export default SignUpEmailConfirmedPage;
