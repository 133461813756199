import { FC, useCallback, useEffect, useState } from "react";
import { useObservable } from "@roketus/web-toolkit";
import { Box, Skeleton } from "@mui/material";
import { getImageService } from "../../../../diContainer/getDependencies";

interface IProps {
  imageId?: string | null;
}

const CardLogo: FC<IProps> = ({ imageId }) => {
  const imageService = getImageService();

  const { loading } = useObservable(imageService.data$) ?? {};

  const [imageUrl, setImageUrl] = useState<string | null>(null);

  const getImgUrl = useCallback(async (id: string) => {
    const url = await imageService.getImageUrlById(id);
    setImageUrl(url);
  }, [imageService]);

  useEffect(() => {
    if (imageId) {
      getImgUrl(imageId);
    }
  }, [imageId, getImgUrl]);

  if (loading) {
    return <Skeleton width={120} height={38} />;
  }

  return (
    <Box>
      <img src={imageUrl ?? ""} alt="Logo" style={{ height: 38 }} />
    </Box>
  );
};

export default CardLogo;
