import { TFunction } from "i18next";
import { IFormFieldConfig } from "../../../boundary/forms/IFormFieldConfig";
import {
  IEmailForm,
  IPhoneForm,
  IProfileBirthForm,
  IProfileGenderForm,
  IProfileWorkplaceForm,
  IProfileAddressForm,
  IProfileInfoForm,
} from "../../../boundary/forms/IProfileForm";
import { regExpIsNameValid } from "../../../domain/specs/validationRules";

export const getProfileInfoFieldsConfig = (
  t: TFunction
): IFormFieldConfig<IProfileInfoForm>[] => [
  {
    name: "firstName",
    label: t("profile.form.firstNameLabel") ?? "",
    type: "text",
    rules: {
      required:
        t("forms.isRequired", { label: t("profile.form.firstNameLabel") }) ??
        "",
      minLength: {
        value: 2,
        message: t("forms.minLength", {
          label: t("profile.form.firstNameLabel"),
        }),
      },
      maxLength: {
        value: 50,
        message: t("forms.maxLength", {
          label: t("profile.form.firstNameLabel"),
        }),
      },
      pattern: {
        value: regExpIsNameValid,
        message: t("forms.nameInvalid", {
          label: t("profile.form.firstNameLabel"),
        }),
      },
    },
  },
  {
    name: "lastName",
    label: t("profile.form.lastNameLabel") ?? "",
    type: "text",
    rules: {
      required:
        t("forms.isRequired", { label: t("profile.form.lastNameLabel") }) ?? "",
      minLength: {
        value: 2,
        message: t("forms.minLength", {
          label: t("profile.form.lastNameLabel"),
        }),
      },
      maxLength: {
        value: 50,
        message: t("forms.maxLength", {
          label: t("profile.form.lastNameLabel"),
        }),
      },
      pattern: {
        value: regExpIsNameValid,
        message: t("forms.nameInvalid", {
          label: t("profile.form.lastNameLabel"),
        }),
      },
    },
  },
  // {
  //   name: "middleName",
  //   label: t("profile.form.middleNameLabel") ?? "",
  //   type: "text",
  //   isOptional: true,
  //   rules: {
  //     minLength: {
  //       value: 2,
  //       message: t("forms.minLength", {
  //         label: t("profile.form.middleNameLabel"),
  //       }),
  //     },
  //     maxLength: {
  //       value: 50,
  //       message: t("forms.maxLength", {
  //         label: t("profile.form.middleNameLabel"),
  //       }),
  //     },
  //     pattern: {
  //       value: regExpIsNameValid,
  //       message: t("forms.nameInvalid", {
  //         label: t("profile.form.middleNameLabel"),
  //       }),
  //     },
  //   },
  // },
];

export const getProfileBirthFieldsConfig = (
  t: TFunction
): IFormFieldConfig<IProfileBirthForm>[] => [
  {
    name: "birth",
    label: t("profile.form.birthDateLabel") ?? "",
    type: "date",
    rules: {
      required:
        t("forms.isRequired", { label: t("profile.form.birthDateLabel") }) ??
        "",
    },
  },
];

export const getProfileGenderFieldsConfig = (
  t: TFunction
): IFormFieldConfig<IProfileGenderForm>[] => [
  {
    name: "gender",
    label: t("profile.form.genderLabel") ?? "",
    type: "select",
    options: [
      { value: "female", label: t("profile.form.gender.female") ?? "" },
      { value: "male", label: t("profile.form.gender.male") ?? "" },
      { value: "other", label: t("profile.form.gender.other") ?? "" },
      { value: "secret", label: t("profile.form.gender.secret") ?? "" },
    ],
  },
];

export const getProfileWorkplaceFieldsConfig = (
  t: TFunction
): IFormFieldConfig<IProfileWorkplaceForm>[] => [
  {
    name: "workplace",
    label: t("profile.form.workplaceLabel") ?? "",
    type: "text",
    rules: {},
  },
];

export const getProfileAddressFieldsConfig = (
  t: TFunction
): IFormFieldConfig<IProfileAddressForm>[] => [
  {
    name: "region",
    label: t("profile.form.regionLabel") ?? "",
    type: "text",
    rules: {
      required:
        t("forms.isRequired", { label: t("profile.form.regionLabel") }) ?? "",
    },
  },
  {
    name: "district",
    label: t("profile.form.districtLabel") ?? "",
    type: "text",
  },
  {
    name: "city",
    label: t("profile.form.cityLabel") ?? "",
    type: "text",
    rules: {
      required:
        t("forms.isRequired", { label: t("profile.form.cityLabel") }) ?? "",
    },
  },
  {
    name: "street",
    label: t("profile.form.streetLabel") ?? "",
    type: "text",
    rules: {
      required:
        t("forms.isRequired", { label: t("profile.form.streetLabel") }) ?? "",
    },
  },
  {
    name: "building",
    label: t("profile.form.buildingLabel") ?? "",
    type: "text",
    span: 6,
  },
  {
    name: "apartment",
    label: t("profile.form.apartmentLabel") ?? "",
    type: "text",
    span: 6,
  },
];

export const getPhoneFieldsConfig = (
  t: TFunction
): IFormFieldConfig<IPhoneForm>[] => [
  {
    name: "phone",
    label: t("profile.form.phone") ?? "",
    hint: t("profile.form.phoneHint") ?? "",
    type: "phone",
    checkVerification: true,
  },
];

export const getEmailFieldsConfig = (
  t: TFunction
): IFormFieldConfig<IEmailForm>[] => [
  {
    name: "email",
    label: t("profile.form.email") ?? "",
    hint: t("profile.form.emailHint") ?? "",
    type: "text",
    rules: {
      required:
        t("forms.isRequired", {
          label: t("profile.form.email"),
        }) ?? "",
      pattern: {
        value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
        message: t("profile.form.emailInvalid") ?? "",
      },
    },
    checkVerification: true,
  },
];
