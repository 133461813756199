import { Paper, styled } from "@mui/material";

export const StyledDownloadCardBox = styled(Paper)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  backgroundColor: theme.palette.mode === "light" ? "#FFFBFB" : "inherit",
  boxShadow: "0px 4px 12px 0px rgba(0,0,0,0.15)",
  borderRadius: 0,
  marginTop: "2rem",
  padding: theme.custom.mainPadding,
  "@media screen and (min-width: 600px)": {
    padding: 0,
    flexDirection: "row",
    alignItems: "center",
    alignSelf: "center",
    backgroundColor: "transparent",
    boxShadow: "none",
    maxWidth: 450,
    margin: "2rem auto 0",
  },
}));
