import { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import "react-phone-number-input/style.css";
import { Trans, useTranslation } from "react-i18next";
import {
  Controller,
  type UseFormSetValue,
  type Control,
  type FieldValues,
  type Path,
  type RegisterOptions,
  type PathValue,
} from "react-hook-form";
import { useObservable } from "@roketus/web-toolkit";
import type { TextFieldProps } from "@mui/material";
import { MuiTelInput, MuiTelInputCountry } from "mui-tel-input";
import {
  DEFAULT_COUNTRY_CODE,
  DEFAULT_LANGUAGE_CODE,
} from "../../../adapters/services/localizationService";
import {
  getIssuerConfigService,
  getLocalizationService,
} from "../../../diContainer/getDependencies";

type IProps<T extends FieldValues> = {
  name: Path<T>;
  control?: Control<T>;
  initialValue?: string;
  label?: string;
  value?: string;
  readOnly?: boolean;
  rules?: Omit<
    RegisterOptions<T, any>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
  setValue?: UseFormSetValue<T>;
} & TextFieldProps;

const MIN_PHONE_LENGTH = 9;

const PhoneInput = <T extends FieldValues>({
  name,
  control,
  initialValue,
  label,
  readOnly,
  rules,
  setValue,
  ...props
}: IProps<T> & TextFieldProps) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "common.phoneInput",
  });
  const { t: tError } = useTranslation("translation", {
    keyPrefix: "common.errors.phone",
  });

  const localizationService = getLocalizationService();
  const issuerConfigService = getIssuerConfigService();

  const localizationData = useObservable(localizationService.data$);
  const issuerSysName = issuerConfigService.getIssuer();

  const [isoCountryCode, isoLangCode]: [MuiTelInputCountry, string] = useMemo(
    () => [
      (localizationData?.country || DEFAULT_COUNTRY_CODE) as MuiTelInputCountry,
      localizationData?.language || DEFAULT_LANGUAGE_CODE,
    ],
    [localizationData]
  );

  // prevent changing initial value with only isoCountryCode
  useEffect(() => {
    if (initialValue && setValue)
      setValue(
        name,
        initialValue as PathValue<T, Path<T> & (string | undefined)>
      );
  }, [name, initialValue, isoCountryCode, isoLangCode, setValue]);

  if (readOnly) {
    return (
      <MuiTelInput
        {...props}
        fullWidth
        label={label}
        preferredCountries={[isoCountryCode]}
        defaultCountry={isoCountryCode}
        langOfCountryName={isoLangCode}
        onChange={() => {}}
      />
    );
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: tError("required") ?? "",
        minLength: {
          value: MIN_PHONE_LENGTH,
          message: tError("phoneIncorrect"),
        },
        ...(rules || {}),
      }}
      render={({ field, fieldState }) => {
        const { value, onChange } = field;
        const errorText = fieldState.error
          ? fieldState.error?.message || (
              <Trans
                t={tError}
                i18nKey={fieldState.error?.type}
                components={{
                  a: <Link to={`/${issuerSysName}/signup`}></Link>,
                }}
              />
            )
          : null;
        
        return (
          <MuiTelInput
            {...props}
            {...field}
            fullWidth
            value={value}
            onChange={onChange}
            preferredCountries={[isoCountryCode]}
            defaultCountry={isoCountryCode}
            langOfCountryName={isoLangCode}
            helperText={errorText}
            error={fieldState.invalid} // labels={ua}
            label={label ?? t("placeholder")}
            sx={{ mt: 2, mb: 1 }}
            data-testid="phone-input"
          />
        );
      }}
    />
  );
};

export default PhoneInput;
