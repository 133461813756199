import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Tabs, Typography } from "@mui/material";
import HowToUsePromo from "./HowToUsePromo/HowToUsePromo";
import PromoCodeList from "./PromoCodeList";
import { StyledTab, StyledTabPanel } from "./styledComponents";
import { InnerContainer } from "../../../../styles/InnerContainer";

const PromoCodeTabs = () => {
  const { t } = useTranslation("translation", { keyPrefix: "promoCodes" });

  const [tabValue, setTabValue] = useState(0);

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Tabs value={tabValue} onChange={handleChange} variant="fullWidth">
        <StyledTab label={t("title")} />
        <StyledTab label={t("whatIsPromo")} />
      </Tabs>
      <StyledTabPanel role="tabpanel" hidden={tabValue !== 0}>
        <InnerContainer>{tabValue === 0 && <PromoCodeList />}</InnerContainer>
      </StyledTabPanel>
      <StyledTabPanel role="tabpanel" hidden={tabValue !== 1}>
        <InnerContainer>
          {tabValue === 1 && (
            <>
              <Typography gutterBottom>
                <Trans
                  t={t}
                  i18nKey={"info"}
                  components={{ strong: <strong /> }}
                />
              </Typography>
              <Typography variant="h5" fontWeight={700} m="1rem 0">
                {t("howToUsePromo")}
              </Typography>
              <HowToUsePromo />
            </>
          )}
        </InnerContainer>
      </StyledTabPanel>
    </>
  );
};

export default PromoCodeTabs;
