import { useTranslation } from "react-i18next";
import type { Control, FieldValues, Path } from "react-hook-form";
import type { TextFieldProps } from "@mui/material";
import Input from "./Input";

interface IProps<T extends FieldValues> {
  name?: Path<T>;
  label?: string;
  control: Control<T>;
}

const EmailInput = <T extends FieldValues>({
  name = "email" as Path<T>,
  label,
  control,
	...props
}: IProps<T> & TextFieldProps & { defaultValue?: undefined }) => {
	const { t } = useTranslation("translation", {
    keyPrefix: "common.emailInput",
  });
  const { t: tError } = useTranslation("translation", {
    keyPrefix: "common.errors.email",
  });

  return (
    <Input
			{...props}
      name={name}
      label={label ?? t("label")}
      control={control}
			fullWidth
      rules={{
        required: tError("emailRequired") ?? "",
        pattern: {
          value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
          message: tError("emailInvalid"),
        },
      }}
    />
  );
};

export default EmailInput;
