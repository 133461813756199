import { FC } from "react";
import warningIcon from "../../../icons/warning.svg";
import DemoatomicLayout from "./DemoatomicLayout";
import DemoatomicHomeButton from "./DemoatomicHomeButton";

export const DemoatomicActionCancel: FC = () => (
  <DemoatomicLayout
    icon={warningIcon}
    title="sorryTitle"
    className="actionResult"
  >
    <DemoatomicHomeButton />
  </DemoatomicLayout>
);
