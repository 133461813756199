import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useObservable } from "@roketus/web-toolkit";
import {
  getProfileService,
  getUpdateProfileService,
} from "../../../diContainer/getDependencies";
import type { IPhoneForm } from "../../../boundary/forms/IProfileForm";
import type { TSetFormFieldError } from "../../../boundary/forms/TSetFormFieldError";
import { getPhoneFieldsConfig } from "./fields";
import FormBuilder from "../form/FormBuilder";

const ProfilePhoneForm: FC = () => {
  const { t } = useTranslation("translation");
  const { t: tError } = useTranslation("translation", { keyPrefix: "common.errors.phone"});

  const profileService = getProfileService();
  const updateProfileService = getUpdateProfileService();

  const profileData = useObservable(profileService.data$);
  const updateProfileData = useObservable(updateProfileService.data$);

  const isLoading = profileData?.loading || updateProfileData?.loading;

  const handlePhoneSave = (
    data: IPhoneForm,
    setError: TSetFormFieldError<IPhoneForm>
  ) => {
    updateProfileService.updatePhone(data, setError);
  };

  const phoneFieldsConfig = getPhoneFieldsConfig(t);

  return (
    <FormBuilder<IPhoneForm>
      tError={tError}
      isLoading={isLoading}
      initialValues={{
        phone: profileData?.profile?.phone,
      }}
      fieldsConfig={phoneFieldsConfig}
      submitLabel={t("profile.form.update")}
      onSave={handlePhoneSave}
    />
  );
};

export default ProfilePhoneForm;
