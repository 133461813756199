import { Fragment, useEffect, useState } from "react";
import { TFunction } from "i18next";
import {
  useForm,
  type FieldValues,
  type DefaultValues,
  type Path,
} from "react-hook-form";
import { Alert, Box, Grid2 as Grid, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import type { IFormFieldConfig } from "../../../boundary/forms/IFormFieldConfig";
import type { TSetFormFieldError } from "../../../boundary/forms/TSetFormFieldError";
import Input from "./Input";
import Select from "./Select";
import PhoneInput from "./PhoneInput";
import DateInput from "./DateInput";
import LoyaltyPolicy from "./LoyaltyPolicy";

interface IProps<T extends FieldValues> {
  tError: TFunction;
  width?: string | number;
  isLoading?: boolean;
  initialValues: DefaultValues<T>;
  fieldsConfig: IFormFieldConfig<T>[];
  submitLabel: string;
  onSave: (data: T, setError: TSetFormFieldError<T>) => void;
}

function FormBuilder<T extends FieldValues>({
  tError,
  width,
  isLoading,
  initialValues,
  fieldsConfig,
  submitLabel,
  onSave,
}: IProps<T>) {
  const { control, handleSubmit, formState, setError, reset } = useForm<T>({
    mode: "onBlur",
    defaultValues: initialValues,
    resetOptions: { keepValues: true, keepDirtyValues: true },
  });

  const { isDirty, isValid, errors } = formState;

  const [isFirstRender, setFirstRender] = useState(false);

  useEffect(() => {
    if (Object.keys(initialValues).length && !isFirstRender) {
      reset(initialValues);
      setFirstRender(true);
    }
  }, [initialValues, reset, isFirstRender]);

  const setFormError = (field: Path<T> | "root", messageKey: string) => {
    const message = tError(messageKey);
    setError(field as "root" | Path<T>, { type: "manual", message });
  };

  const handleSave = (data: T) => {
    onSave(data, setFormError);
  };

  const getField = ({
    name,
    label,
    type,
    rules,
    options,
    textFieldProps,
  }: IFormFieldConfig<T>) => {
    if (type === "phone") {
      return (
        <PhoneInput<T>
          name={name as Path<T>}
          control={control}
          rules={rules}
          label={label}
          fullWidth
        />
      );
    }

    if (type === "select") {
      return (
        <Select<T>
          name={name as Path<T>}
          control={control}
          label={label}
          rules={rules}
          options={options}
          fullWidth
          margin="normal"
          variant="outlined"
          slotProps={{
            inputLabel: {
              // shrink: true,
            },
          }}
        />
      );
    }

    if (type === "date") {
      return (
        <DateInput
          {...(textFieldProps || {})}
          name={name as Path<T>}
          control={control}
          label={label}
          rules={rules}
          margin="normal"
        />
      );
    }

    if (type === "agreement") {
      return <LoyaltyPolicy<T> control={control} name={name as Path<T>} />;
    }

    return (
      <Input<T>
        {...(textFieldProps || {})}
        name={name as Path<T>}
        control={control}
        type={type}
        rules={rules}
        label={label}
        fullWidth
        margin="normal"
        variant="outlined"
        slotProps={{
          inputLabel: {
            // shrink: true
          },
        }}
      />
    );
  };

  const renderField = (fieldConfig: IFormFieldConfig<T>) => {
    const { type, name, label, hint, span, fields } = fieldConfig;

    if (type === "group" && fields) {
      return (
        <Fragment key={name as string}>
          <Grid size={12}>
            <Typography variant="body1" mt={1}>
              {label}
            </Typography>
          </Grid>
          {fields.map(renderField)}
        </Fragment>
      );
    }

    return (
      <Fragment key={name as string}>
        {hint && (
          <Grid size={12}>
            <Typography variant="body2">{hint}</Typography>
          </Grid>
        )}

        <Grid size={span ?? 12}>{getField(fieldConfig)}</Grid>
      </Fragment>
    );
  };

  return (
    <Box
      component="form"
      sx={{
        mb: 2,
        display: "flex",
        flexDirection: "column",
        width: width ?? "100%",
      }}
    >
      <Grid container columnSpacing={2}>
        {fieldsConfig.map(renderField)}
      </Grid>

      {errors?.root && <Alert severity="error">{errors?.root.message}</Alert>}

      <LoadingButton
        type="submit"
        disabled={!isValid || !isDirty}
        loading={isLoading}
        variant="contained"
        color="primary"
        fullWidth
        sx={{ mt: 3, display: "block", marginLeft: "auto" }}
        onClick={handleSubmit(handleSave)}
      >
        {submitLabel}
      </LoadingButton>
    </Box>
  );
}

export default FormBuilder;
