import { FC, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { useObservable } from "@roketus/web-toolkit";
import { Typography } from "@mui/material";
import { useQuery } from "../../../adapters/hooks/useQuery";
import { buildAnalyticMessageEntity } from "../../../domain/entities/analyticEntity";
import { AuthMethod } from "../../../domain/specs/authMethods";
import type { IPhoneFormData } from "../../../boundary/IPhoneFormData";
import type { IEmailFormData } from "../../../boundary/IEmailFormData";
import type { TSetFormFieldError } from "../../../boundary/forms/TSetFormFieldError";
import {
  getIssuerConfigService,
  getMessageBus,
  getSignupService,
} from "../../../diContainer/getDependencies";
import { getRegStartParamsFromQuery } from "../../../utils/registration";
import LayoutHeadless from "../../components/layouts/LayoutHeadless";
import { StyledLink } from "../../../styles/StyledLink";
import AuthForm from "../../components/authForms/AuthForm";

const SignUpPage: FC = () => {
  const { t } = useTranslation("translation", { keyPrefix: "signUp.index" });

  const navigate = useNavigate();
  const query = useQuery();
  const { issuerSysName } = useParams();

  const [analyticsMeta, regParams] = useMemo(() => {
    const { refCode, companyId, shop, campaign, templateId } =
      getRegStartParamsFromQuery(query);

    const meta = {
      companyId,
      campaign,
      shop,
    };
    const params = {
      ref_code: refCode,
      company_id: companyId,
      template_id: templateId,
      shop,
    };
    return [meta, params];
  }, [query]);

  const issuerConfigService = getIssuerConfigService();
  const signupService = getSignupService();
  const messageBus = getMessageBus();

  const issuerData = useObservable(issuerConfigService.data$);
  const signupData = useObservable(signupService.data$);

  const signupByPhone = (
    phone: string,
    setError: TSetFormFieldError<IPhoneFormData>
  ) => {
    const analyticEvent = buildAnalyticMessageEntity({
      action: "SignUpByPhoneButtonClick",
      source: "SignUpPage",
      meta: analyticsMeta,
    });
    messageBus.send(analyticEvent);

    signupService.signupByPhone(phone, regParams, setError);
  };

  const signupByEmail = (
    email: string,
    setError: TSetFormFieldError<IEmailFormData>
  ) => {
    const analyticEvent = buildAnalyticMessageEntity({
      action: "SignUpByEmailButtonClick",
      source: "SignUpPage",
      meta: analyticsMeta,
    });
    messageBus.send(analyticEvent);

    signupService.signupByEmail(email, regParams, setError);
  };

  const signupByGoogle = () => {
    const analyticEvent = buildAnalyticMessageEntity({
      action: "SignUpByGoogleButtonClick",
      source: "SignUpPage",
      meta: analyticsMeta,
    });
    messageBus.send(analyticEvent);

    signupService.signupByGoogle(regParams);
  };

  const changeSignupMethod = (method: AuthMethod) => {
    switch (method) {
      case AuthMethod.Google:
        signupByGoogle();
        break;
      case AuthMethod.Phone:
      case AuthMethod.Email:
      default:
        signupService.setSignupMethod(method);
    }
  };

  const goToSignIn = (e: React.MouseEvent) => {
    // authService.removeTokens();
    navigate(`/${issuerSysName}/signin`);
  };

  return (
    <LayoutHeadless
      title={
        issuerData?.config?.companyName ? (
          <Trans
            t={t}
            i18nKey="header"
            values={{ company: issuerData.config.companyName }}
          />
        ) : (
          ""
        )
      }
    >
      <Typography variant="body1" pb={2}>
        <Trans t={t} i18nKey="text" components={{ bold: <strong /> }} />
      </Typography>

      <AuthForm
        t={t}
        hasPolicy
        initialValues={
          signupData?.phoneNumber
            ? { phone: signupData?.phoneNumber }
            : undefined
        }
        loading={signupData?.loading}
        method={signupData?.signupMethod}
        setAuthMethod={changeSignupMethod}
        authByPhone={signupByPhone}
        authByEmail={signupByEmail}
      />

      <Typography>
        {t("hasAccount")}{" "}
        <StyledLink
          component="button"
          data-testid="signin"
          onClick={goToSignIn}
        >
          {t("signIn")}
        </StyledLink>
      </Typography>
    </LayoutHeadless>
  );
};

export default SignUpPage;
