import type { TFunction } from "i18next";
import { Divider } from "@mui/material";
import { AuthMethod } from "../../../domain/specs/authMethods";
import type { IPhoneFormData } from "../../../boundary/IPhoneFormData";
import type { IEmailFormData } from "../../../boundary/IEmailFormData";
import type { TSetFormFieldError } from "../../../boundary/forms/TSetFormFieldError";
import AuthOptions from "../authOptions/AuthOptions";
import PhoneForm from "./PhoneForm";
import EmailForm from "./EmailForm";

interface IProps {
  t: TFunction;
  initialValues?: IPhoneFormData;
  loading?: boolean;
  hasPolicy?: boolean;
  method?: AuthMethod;
  setAuthMethod: (method: AuthMethod) => void;
  authByPhone: (
    phone: string,
    setError: TSetFormFieldError<IPhoneFormData>
  ) => void;
  authByEmail: (
    email: string,
    setError: TSetFormFieldError<IEmailFormData>
  ) => void;
}

const AuthForm = ({
  t,
  initialValues,
  loading,
  hasPolicy,
  method,
  setAuthMethod,
  authByPhone,
  authByEmail,
}: IProps) => {
  return (
    <>
      {method === AuthMethod.Phone && (
        <PhoneForm<IPhoneFormData>
          loading={loading}
          initialValues={initialValues}
          hasPolicy={hasPolicy}
          submitLabel={t("submit")}
          onSubmit={authByPhone}
        />
      )}

      {method === AuthMethod.Email && (
        <EmailForm<IEmailFormData>
          loading={loading}
          hasPolicy={hasPolicy}
          submitLabel={t("submit")}
          onSubmit={authByEmail}
        />
      )}

      {/* <Divider
        sx={{
          width: "100%",
          mb: 2,
          "&::before, &::after": { borderColor: "#000000" },
        }}
      >
        {t("or")}
      </Divider>

      <AuthOptions
        t={t}
        currentMethod={method}
        onMethodChanged={setAuthMethod}
      /> */}
    </>
  );
};

export default AuthForm;
