import { FC, ReactElement } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { StyledCard } from "./styledComponents";
import BackButton from "../common/BackButton";
import { getRouterService } from "../../../diContainer/getDependencies";

interface IProps {
  title?: string;
  hint?: string;
  backUrl?: string | null;
  canClose?: boolean;
  children: ReactElement;
}

const ProfileSettingsCard: FC<IProps> = ({
  title,
  hint,
  backUrl,
  canClose = true,
  children,
}) => {
  const routerService = getRouterService();

  const handleClose = () => {
    routerService.navigateToIssuerPath("/");
  };

  return (
    <StyledCard>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
      >
        {backUrl && <BackButton backUrl={backUrl} />}
        {canClose && (
          <IconButton
            sx={{ padding: "0.5rem 0", marginLeft: "auto" }}
            onClick={handleClose}
          >
            <CloseIcon
              fontSize="medium"
              sx={(theme) => ({
                color: theme.palette.mode === "light" ? "#000000" : "inherit",
              })}
            />
          </IconButton>
        )}
      </Box>

      {!!title && (
        <Typography variant="h5" mb={3}>
          {title}
        </Typography>
      )}

      {!!hint && <Typography variant="subtitle1" fontSize={12}>{hint}</Typography>}

      {children}
    </StyledCard>
  );
};

export default ProfileSettingsCard;
