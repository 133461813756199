import { FC } from "react";
import warningIcon from "../../../icons/warning.svg";
import DemoatomicLayout from "./DemoatomicLayout";
import DemoatomicHomeButton from "./DemoatomicHomeButton";

const DemoatomicActionError: FC = () => (
  <DemoatomicLayout
    icon={warningIcon}
    title="errorTitle"
    className="actionResult"
  >
    <DemoatomicHomeButton />
  </DemoatomicLayout>
);

export default DemoatomicActionError;
